import React from "react";
import { useEffect, useState } from "react";
import store from "../../store";
import { get } from "lodash";
import orders from "../../api/orders";
import { ShoppingCartIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart } from "../../actions/cartAction";
import { useAlert } from "react-alert";

const OrderItemsSidebarCard = ({ order_item, setIsOpenOrderItemsSidebar }) => {
  // console.log(order_item.add_ons);
  const dispatch = useDispatch();
  const alert = useAlert();
  useEffect(() => {
    getImage(order_item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_item]);
  const [image, setImage] = useState(null);
  const [product, setProduct] = useState(null);
  const getImage = async (order_item) => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: {
        token: token,
      },
    };
    const product = order_item.product_id;
    const response = await orders.get(
      `get_single_product?product=${product}`,
      config
    );
    if (response.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }
    setImage(response);
    setProduct(response.data.data);
  };
  const { cartItems } = useSelector((state) => state.cart);
  const addToCartHandler = () => {
    let count = 0;
    const qty = 1;
    const addOn = order_item.add_ons;
    const addOnPrice = order_item.add_ons_price;
    const food_type = order_item.food_type;
    const prepare_time = order_item.prepare_time;
    const product_type = order_item.food_type;
    const addComment = order_item.comment;
    const sku = order_item.order_sku;
    const id = order_item.product_id;
    // console.log(adds);
    if (cartItems.length === 0) {
      dispatch(
        addItemsToCart(
          sku,
          id,
          qty,
          order_item.add_ons,
          addOnPrice,
          food_type,
          prepare_time,
          product_type,
          addComment,
          product,
          false,
          ""
        )
      );
      alert.success("Added the item to the cart");
      // setIsOpenOrderItemsSidebar(false);
    } else {
      for (let i = 0; i < cartItems.length; i++) {
        if (sku === cartItems[i].sku) {
          count = 1;
        }
      }
      if (count === 0) {
        dispatch(
          addItemsToCart(
            sku,
            id,
            qty,
            order_item.add_ons,
            addOnPrice,
            food_type,
            prepare_time,
            product_type,
            addComment,
            product,
            false,
            ""
          )
        );
        alert.success("Added the item to the cart");
      } else {
        alert.info("Item is Already in the cart");
      }
    }
  };
  if (!image) {
    return <div></div>;
  }
  const objectToArray = (obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    let arr = [];
    keys.forEach((key, i) => {
      let val = values[i];
      let desc = "";
      val.forEach((obj, index) => {
        if (index === val.length - 1) desc += obj.value;
        else desc += (obj.value + ", ");
      })
      let obj = {
        title: key,
        desc: desc
      };
      arr.push(obj);
    })
    return arr;
  }
  let adds = objectToArray(order_item.add_ons);
  return (
    <div className="flex flex-row justify-between items-center w-full gap-2 my-2 ">
      <div className="flex flex-row gap-2">
        <div className="flex-none relative w-[60px] h-[60px] rounded-[10px] cursor-pointer ">
          <img
            alt="orderitempic"
            className="w-full h-full rounded-[10px] object-cover"
            src={image.data.data?.product_list?.card_img}
          />
          <span className="text-[11px] rounded-full bg-primaryColor text-stone-50 p-0.1 px-1 absolute top-[-5px] right-[-5px]">
            {order_item?.quantity || "1"}
          </span>
        </div>

        <div>
          <h2 className="font-bold text-gray-800 capitalize text-md line-clamp-1 leading-tight cursor-pointer">
            {order_item?.product_name}
          </h2>
          <div className="font-bold text-sm text-gray-500  leading-tight capitalize truncate">
            <p>Food Type: {order_item.food_type}</p>
            {adds.map((addon, i) => <div key={i}><span className="mr-1">{/* {addon.title}: */} {addon.desc}{i !== adds.length - 1 && ', '}</span> <br /> </div>)}
            {/* {Object.entries(order_item.add_ons).map(([key, val], i) => (
              <span className="mr-1" key={i}>
                {key}: {val}
                {i !== Object.entries(order_item.add_ons).length - 1
                  ? ", "
                  : ""}
              </span>
            ))} */}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center w-8 h-8 rounded-md  hover:bg-primaryColor/20 cursor-pointer">
        <button className="text-primaryColor hover:text-primaryDarkColor  ">
          <ShoppingCartIcon className="w-5 h-5" onClick={addToCartHandler} />
        </button>
      </div>
    </div>
  );
};

export default OrderItemsSidebarCard;
