export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';


export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';


export const SELECT_BRANCH_REQUEST = 'SELECT_BRANCH_REQUEST';
export const SELECT_BRANCH_SUCCESS = 'SELECT_BRANCH_SUCCESS';
export const SELECT_BRANCH_FAIL = 'SELECT_BRANCH_FAIL';


export const BRANCH_VERIFY_REQUEST = 'BRANCH_VERIFY_REQUEST';
export const BRANCH_VERIFY_SUCCESS = 'BRANCH_VERIFY_SUCCESS';
export const BRANCH_VERIFY_FAIL = 'BRANCH_VERIFY_FAIL';


export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS';
export const NOTIFICATION_FAIL = 'NOTIFICATION_FAIL';