import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import { BellIcon, XCircleIcon, XIcon } from "@heroicons/react/outline";
import orders from "../../api/orders";
import store from "../../store";
import { get } from "lodash";
import { useState } from "react";
import Moment from "react-moment";
import { fetch_notifications } from "../../actions/userActions";
import moment from "moment";

const Notification = () => {
  const dispatch = useDispatch();
  const [expandedIndexes, setExpandedIndexes] = useState([]);


  const employeeIdFromStore = useSelector((state) => state.userDetails?.user?.employee_id);

  const branchIdFromStore = useSelector((state) => state.userDetails?.user?.branch_id);

  const notifications = useSelector((state) => state.notification?.notification?.data)

  // fetchNotifications()
  useEffect(() => {

    const interval = setInterval(() => {
      dispatch(fetch_notifications(branchIdFromStore, employeeIdFromStore))
    }, 60000);
    dispatch(fetch_notifications(branchIdFromStore, employeeIdFromStore))

    return () => clearInterval(interval);

  }, [branchIdFromStore, dispatch, employeeIdFromStore])

  const toggleExpanded = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  return (
    <Popover className="relative">
      <Popover.Button className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] ">
        {notifications && (<span className="flex h-3 w-3 absolute -top-1 -right-1">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>)}
        <BellIcon className="h-6 w-6" />
      </Popover.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className="absolute right-0 z-999 bg-white rounded-[10px] p-2 shadow-md my-2 w-[300px]">
          <div className="flex justify-between items-center border-b-[1px] pb-1 mb-2">
            <h5 className="font-bold mb-0 text-primaryColor">Notifications</h5>
          </div>
          <div className="notification-items overflow-auto max-h-[300px] pr-1">
            {notifications ? (
              <>
                {notifications && notifications.map((notification, idx) => (
                  <div onClick={() => toggleExpanded(idx)} key={idx} className="notification-item bg-primaryLightColor25 p-1 rounded-[10px] mb-1 border-l-4 border-primaryColor/50 cursor-pointer">
                    <div className="flex justify-between items-center px-2">
                      <div className="text-primaryColor">
                        {expandedIndexes.includes(idx) ? (<h3 className="text-base font-semibold">{notification.title}</h3>) : (<h3 className="text-base font-semibold  line-clamp-1">{notification.title}</h3>)}
                        {expandedIndexes.includes(idx) ? (<p className="text-sm">{notification.body}</p>) : (<p className="text-sm  line-clamp-2">{notification.body}</p>)}
                        <h2 className="text-sm font-semibold text-primaryColor"><Moment tz="Asia/Kolkata" format="MMM DD, YYYY " className="text-sm">{notification?.notification_date}</Moment></h2>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : 'No Notification Found'}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Notification;
