import { get } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../screen/auth/Login";
import HomeScreen from "../screen/home/HomeScreen";
import { detailsUser } from "../actions/userActions";
import SuccessPage from "../components/home/SuccessPage";
import OrderGotHold from "../components/home/OrderGotHold";
//import LogoutEmployeeOrders from "../components/home/LogoutEmployeeOrders";
import OrderHistory from "../screen/OrderHistory";
import OnHoldOrders from "../screen/OnHoldOrders";
import Loader from "../components/loader/Loader";
import LogoutEmployeeOrders3 from "../components/home/LogoutEmployeeOrders3";
import EditOnHoldOrder from "../screen/EditOnHoldOrder";
import CancelledPage from "../components/home/CancelledPage";
import { AppContextProvider } from "../context/AppContext";
import Error from "../components/home/Error";
import LogoutReports from "../screen/LogoutReports";
import LogoutSingleReport from "../screen/LogoutSingleReport";
import LoginSelectBranch from "../screen/auth/LoginSelectBranch";
import LoginBranchVerify from "../screen/auth/LoginBranchVerify";
const Routing = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const token = get(userLogin, "userInfo.token");

  useEffect(() => {
    if (!!token) {
      dispatch(detailsUser());
    }
  }, [dispatch, token]);



  return (
    <BrowserRouter>
      <AppContextProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/select-branch" element={<LoginSelectBranch />} />
          <Route path="/branch-verify" element={<LoginBranchVerify />} />
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancelled" element={<CancelledPage />} />
          <Route path="/orderonhold" element={<OrderGotHold />} />
          {/* <Route
            path="/logoutEmployeeOrders2"
            element={<LogoutEmployeeOrders />}
          /> */}
          <Route
            path="/logoutEmployeeOrders"
            element={<LogoutEmployeeOrders3 />}
          />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/onhold-orders" element={<OnHoldOrders />} />
          <Route
            exact
            path="/onhold-orders/:orderId"
            element={<EditOnHoldOrder />}
          />
          <Route path="/loader" element={<Loader />} />
          <Route path="/logout-reports" element={<LogoutReports />} />
          <Route path="/logout-report" element={<LogoutSingleReport />} />
          <Route path="*" element={<Error />} />

        </Routes>
      </AppContextProvider>
    </BrowserRouter>
  );
};

export default Routing;
