import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useAlert } from "react-alert";

import { useSelector } from "react-redux";


import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import store from "../../store";
import orders from "../../api/orders";

const HoldHoldModal = ({
    isopenhold,
    setIsOnHoldOpen,
    subTotal,
    orderId,
    customerInfoRenderData,
    stop,
    duration,
    gettingActiveCustomerInfo
}) => {
    const navigate = useNavigate();

    let completeButtonRef = useRef(null);

    const branchIdfromStore = useSelector(
        (state) => state.userDetails?.user?.branch_id
    );

    const addressfromstore = useSelector(
        (state) => state.userDetails?.user?.address
    );
    const employeeIdFromStore = useSelector(
        (state) => state.userDetails?.user?.employee_id
    );

    const branchNameFromStore = useSelector(
        (state) => state.userDetails?.user?.branch
    );

    let cartItemsData = JSON.parse(sessionStorage.getItem("cartItems"));

    let cartItemsModified = [];
    //MAPPING THE CART ITEMS INTO THE CART ITEMS MODIFIED ARRAY
    if (cartItemsData != null) {
        for (let i = 0; i < cartItemsData.length; i++) {
            const item = cartItemsData[i];
            let total_price = 0;
            total_price += (Number(item.price + item.add_on_price) * item.qty);
            // let index = -1;
            // item.add_ons.forEach((addOn) => {
            //   adds.forEach((x, i) => {
            //     if (x.title === addOn.title) {
            //       x.desc = x.desc + ', ' + addOn.desc;
            //       index = i;
            //     }
            //   })
            //   if (index === -1) {
            //     adds = [...adds, {
            //       title: addOn.title,
            //       desc: addOn.desc
            //     }]
            //   }
            // });
            // adds = adds.reduce(
            //   (obj, item) => Object.assign(obj, { [item.title]: item.desc }), {});
            cartItemsModified.push({
                product_id: item.id,
                product_name: item.name,
                quantity: item.qty,
                price: item.price,
                total_price: total_price,
                add_ons: item.add_ons,
                add_ons_price: item.add_on_price,
                discount: item.discount !== undefined ? item.discount : 0,
                product_type: item.product_type,
                prepare_time: item.prepare_time,
                food_type: item.food_type,
                order_sku: item.sku,
                comment: item.note,
            });
        }
    }



    const alert = useAlert();

    function closeHoldModal() {
        setIsOnHoldOpen(false);
    }

    const Onhold = () => {
        const token = get(store.getState(), "userLogin.userInfo.token");

        const config = {
            headers: { "Content-Type": "application/json", token: token },
        };
        orders
            .post(
                "/update_hold_order",
                {
                    order_id: orderId,
                    customer_no: customerInfoRenderData.mobile_no,
                    employee_id: employeeIdFromStore,
                    branch_id: branchIdfromStore,
                    branch_name: branchNameFromStore,
                    paid_price: null,
                    sub_total: subTotal,
                    discount: null,
                    applied_coupons: {
                        coupon: null,
                    },
                    comment: null,
                    status: "Hold",
                    paid: false,
                    tax: null,
                    payment_method: null,
                    payment_id: null,
                    account_id: null,
                    received: null,
                    change: null,
                    order_type: null,
                    address: addressfromstore,
                    order_items: cartItemsModified,
                    ord_rec_time: duration,
                },
                config
            )
            .then((res) => {
                if (res.data.status === "success") {
                    alert.success("Your order is on hold!");
                    sessionStorage.removeItem("cartItems");
                    sessionStorage.removeItem("customerInfo");
                    stop();
                    navigate("/orderonhold");
                    return;
                }
                if (res.data.status === "failure") {
                    alert.error(`${res.data.msg}`);
                    return;
                }
            })
            .catch((error) => { console.log(error) });
    };

    return (
        <>
            <Transition appear show={isopenhold} as={Fragment}>
                <Dialog
                    initialFocus={completeButtonRef}
                    as="div"
                    className="fixed inset-0 "
                    style={{ zIndex: "70" }}
                    onClose={() => setIsOnHoldOpen(true)}
                >
                    <div ref={completeButtonRef} className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="absolute inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-xs p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title className="text-center text-xl font-bold px-5 mb-5">
                                    Are You Sure you would like to put your orders on hold?
                                </Dialog.Title>

                                <div className="flex mt-2 gap-2">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryColor border border-transparent rounded-[10px] hover:bg-primaryDarkColor w-2/3"
                                        onClick={Onhold}
                                    >
                                        Yes, I'm Sure
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/3 "
                                        onClick={closeHoldModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default HoldHoldModal;
