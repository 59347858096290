import {
  PROMO_CODE_FAIL,
  PROMO_CODE_REQUEST,
  PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_SUCCESS
} from "../constants/promoCodeConstants";
import { get } from "lodash";
import store from "../store";
import orders from "../api/orders";

//ACTION TO GET THE PRODUCT LIST BASED ON TH SEARCH QUERY AND/OR CATEGORY OR GETTING A DEFAULT LIST OF ALL ITEMS OTHERWISE.
export const getPromoCode = () =>
  async (dispatch) => {
    dispatch({ type: PROMO_CODE_REQUEST });

    try {
      //GETTING THE TOKEN AND ADDING IT TO CONFIG
      const token = get(store.getState(), "userLogin.userInfo.token");
      const config = {
        headers: { "Content-Type": "application/json", token: token },
      };
      //USING TERNARY OPERATORS TO MAKE REQUEST ACCORDINGLY
      const { data } = await orders.post(`/get_promo_code`, {}, config);

      //DISPATCHING THE RECEIVED LIST OF PRODUCTS
      dispatch({ type: PROMO_CODE_SUCCESS, payload: { data } });
    } catch (error) {
      //DISPATCHING THE ERROR IF FOUND.
      dispatch({
        type: PROMO_CODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const applyPromoCode = (data) => {
  return function (dispatch, getState) {
    dispatch({
      type: APPLY_PROMO_CODE_SUCCESS,
      payload: data
    })
  }
}