import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { branchVerify, selectBranch } from "../../actions/userActions";
import { useAlert } from "react-alert";
import Logo from '../../assets/images/logo.png';

const LoginBranchVerify = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const alert = useAlert();

  // set states for email and password

  const [otp, setOtp] = useState();

  // redirect user to shipping screen after sign in
  //first check if there is redirect query param on the url
  const redirect = /* location.search ? location.search.split("=")[1] : */ "../";

  // get userinfo from redux store
  const userLogin = useSelector((state) => state.userLogin);
  const userBranch2 = useSelector((state) => state.userBranch);
  const verifyBranch2 = useSelector((state) => state.verifyBranch);
  const { userInfo } = userLogin;
  const { userBranch } = userBranch2;
  const { verifyBranch } = verifyBranch2;

  //console.log(userBranch2);
  const dispatch = useDispatch();




  // handle verify branch submit
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(branchVerify(otp, userBranch?.branch_id, userBranch?.employee_id));
  };

  useEffect(() => {
    if (verifyBranch && verifyBranch?.status === "success") {
      navigate(redirect);
      //dispatch(login(email, password));
      window.location.reload();

      //alert.success(verifyBranch?.msg);
    } else if (verifyBranch && verifyBranch?.status === "failure") {
      alert.error(verifyBranch?.msg);
    }
  }, [verifyBranch, navigate, redirect, alert]);

  //resend otp
  const resendOTP = () => {
    dispatch(selectBranch(userBranch.employee_id, userBranch.branch_name, userBranch.branch_id));
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (!userBranch) {
      navigate("/select-branch");
    }
  }, [userInfo, navigate, userBranch]);


  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/3">
        <div className="flex flex-col justify-center items-center h-screen">
          <img
            src={Logo}
            alt="HeeBee"
            className="w-[50px] mb-2 rounded-full"
          />
          <h1 className="text-2xl textColor font-bold mb-1">Welcome Back!</h1>
          <p className="font-bold mb-4 mutedColor">
            Enter OTP continue to HeeBee Web Pos.
          </p>
          <form className="w-10/12" autoComplete="off" onSubmit={submitHandler}>
            <div className="outline outline-primaryLightColor30 relative input-primary my-5 p-1 ">
              <input
                type="number"
                name="otp"
                placeholder=" "
                className="block py-2 px-2 w-full text-lg appearance-none focus:outline-none bg-transparent"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                autoFocus={true}
                required
                autoComplete="off"
              />
              <label
                htmlFor="otp"
                className="absolute top-0 text-md bg-transparent p-3 px-1 z-1 duration-300 origin-0"
              >
                OTP
              </label>
            </div>
            <div className="w-full mb-2">
              <button type="submit" className="button-primary">
                Verify Branch
              </button>
            </div>
          </form>
          <div className="w-10/12 mb-5">
            <button onClick={resendOTP} className="button-primary opacity-50">
              Resend OTP
            </button>
          </div>
        </div>
      </div>
      <div className="h-screen w-full md:w-2/3 hidden md:block">
        <div className="authentication-bg d-none d-md-block">
          <div className="bg-overlay"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginBranchVerify;
