import React, { useState } from "react";
import {
  CollectionIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import VegIcon from "../../assets/images/green-circle.svg";
import NonVegIcon from "../../assets/images/red-circle.svg";
import Modal from "../../components/product/Modal";
import ReactTooltip from "react-tooltip";
import DiscountedProductAddToCardModal from "../home/DiscountedProductAddToCardModal";
import DiscountedAddonAddToCartModal from "../home/DiscountedAddonAddToCartModal";

const Product = ({ product }) => {
 // console.log(product, "product");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="product-card max-w-sm rounded-[10px] p-2 overflow-hidden shadow-md bg-white relative cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <img
        className="w-full rounded-[10px]"
        src={product?.product_list.card_img}
        alt={product?.product_list.product_name}
      />
      <span className="bg-white font-bold text-primaryColor px-2 py-0 absolute left-3 top-3 rounded-[10px] backdrop-blur-sm text-sm">
        {product.product_list.sku}
      </span>
      <img
        src={product.product_list.food_type === "Veg" ? VegIcon : NonVegIcon}
        alt="Veg"
        width="20"
        className="absolute right-3 top-3 rounded-[10px] backdrop-blur-sm text-sm p-0.5 bg-white border-2 "
      />
      <div className="product-card-body mt-2">
        <div className="flex justify-between items-center mb-1">
          <h6 className="text-textColor font-bold line-clamp-2 capitalize leading-tight	">
            {product.product_list.product_name}
          </h6>

          <span
            data-tip={product.product_list.description}
            className="text-orange-500"
          >
            <InformationCircleIcon className="w-6 h-6" />{" "}
          </span>
          <ReactTooltip />
        </div>
        <div className="flex justify-between items-center">
          <h6 className="text-primaryColor font-bold capitalize text-lg">
            &#8377;{product.product_list.price + product.price}
          </h6>
          <h6 className="text-mutedColor font-bold text-sm capitalize flex">
            <CollectionIcon className="w-5 h-5 mr-1" data-tip="Availability" />
            {product.items_available}
          </h6>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        product={product}
        edit={false}
        editItem={null}
      />
    </div>
  );
};

export default Product;

export const DiscountedProduct = ({
  discountedProduct,
  setDiscountedProductModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="product-card max-w-[15rem] max-h-[20rem] rounded-[10px] p-2 overflow-hidden shadow-md bg-white relative cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <img
        className="w-full rounded-[10px]"
        src={discountedProduct?.card_img}
        alt={discountedProduct?.product_name}
      />
      <span className="bg-white font-bold text-primaryColor px-2 py-0 absolute left-3 top-3 rounded-[10px] backdrop-blur-sm text-sm">
        {discountedProduct.sku}
      </span>
      <img
        src={discountedProduct.food_type === "Veg" ? VegIcon : NonVegIcon}
        alt="Veg"
        width="20"
        className="absolute right-3 top-3 rounded-[10px] backdrop-blur-sm text-sm p-0.5 bg-white border-2 "
      />
      <div className="product-card-body mt-2">
        <div className="flex justify-between items-center mb-1">
          <h6 className="text-textColor font-bold line-clamp-2 capitalize leading-tight	">
            {discountedProduct.product_name}
          </h6>

          <span
            data-tip={discountedProduct.description}
            className="text-orange-500"
          >
            <InformationCircleIcon className="w-6 h-6" />{" "}
          </span>
          <ReactTooltip />
        </div>
        <div className="flex justify-between items-center">
          <h6 className="text-primaryColor font-bold capitalize text-lg">
            &#8377;
            {discountedProduct?.products[0].price + discountedProduct?.price}
          </h6>
          <h6 className="text-mutedColor font-bold text-sm capitalize flex">
            <CollectionIcon className="w-5 h-5 mr-1" data-tip="Availability" />
            {discountedProduct?.products[0].items_available}
          </h6>
        </div>
      </div>

      <DiscountedProductAddToCardModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        product={discountedProduct}
        edit={false}
        editItem={null}
        setDiscountedProductModal={setDiscountedProductModal}
      />
    </div>
  );
};

export const DiscountedProductForAddon = ({
  discountedAddonProduct,
  setDiscountedAddonsProductModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="product-card max-w-[15rem] max-h-[20rem] rounded-[10px] p-2 overflow-hidden shadow-md bg-white relative cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <img
        className="w-full rounded-[10px]"
        src={discountedAddonProduct?.card_img}
        alt={discountedAddonProduct?.product_name}
      />
      <span className="bg-white font-bold text-primaryColor px-2 py-0 absolute left-3 top-3 rounded-[10px] backdrop-blur-sm text-sm">
        {discountedAddonProduct.sku}
      </span>
      <img
        src={discountedAddonProduct.food_type === "Veg" ? VegIcon : NonVegIcon}
        alt="Veg"
        width="20"
        className="absolute right-3 top-3 rounded-[10px] backdrop-blur-sm text-sm p-0.5 bg-white border-2 "
      />
      <div className="product-card-body mt-2">
        <div className="flex justify-between items-center mb-1">
          <h6 className="text-textColor font-bold line-clamp-2 capitalize leading-tight	">
            {discountedAddonProduct.product_name}
          </h6>

          <span
            data-tip={discountedAddonProduct.description}
            className="text-orange-500"
          >
            <InformationCircleIcon className="w-6 h-6" />{" "}
          </span>
          <ReactTooltip />
        </div>
        <div className="flex justify-between items-center">
          <h6 className="text-primaryColor font-bold capitalize text-lg">
            &#8377;
            {discountedAddonProduct?.products[0].price +
              discountedAddonProduct?.price}
          </h6>
          <h6 className="text-mutedColor font-bold text-sm capitalize flex">
            <CollectionIcon className="w-5 h-5 mr-1" data-tip="Availability" />
            {discountedAddonProduct?.products[0].items_available}
          </h6>
        </div>
      </div>

      <DiscountedAddonAddToCartModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        product={discountedAddonProduct}
        edit={false}
        editItem={null}
        setDiscountedAddonsProductModal={setDiscountedAddonsProductModal}
      />
    </div>
  );
};
