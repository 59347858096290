import orders from "../api/orders";
import store from "../store";
import { get } from "lodash";

//ACTION TO FETCH ALL ORDERS ON THE ORDER-HISTORY PAGE
export const fetchAllOrders = (page, employee_id, branch_id) => async (dispatch) => {
  //FETCHING THE TOKEN AND ADDING IT TO CONFIG
  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  //FETCHING THE ALL ORDERS LIST
  if (branch_id !== undefined && employee_id !== undefined) {
    const response = await orders.get(
      `/fetch_all_orders/${page}?employee=${employee_id}&branch=${branch_id}`,
      config
    );
    //REDIRECTING TO LOGIN PAGE IF THE TOKEN CHANGES
    if (response.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }
    //DISPATCHING THE FETCHED LIST
    dispatch({
      type: "FETCH_ALL_ORDERS",
      payload: response.data,
    });
  }
};

//FETCH COMPLETED ORDERS

export const fetchOrders = (page, employee_id, branch_id) => async (dispatch) => {
  //FETCHING THE TOKEN AND ADDING IT TO CONFIG
  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  //FETCHING THE COMPLETED ORDERS LIST
  const response = await orders.get(
    `/get_emp_completed_orders_logout_time/${page}?employee=${employee_id}&branch=${branch_id}`,
    config
  );
  //REDIRECTING TO LOGIN PAGE IF THE TOKEN CHANGES
  if (response.data.data === "Token does not match") {
    sessionStorage.clear();
    window.location.href = "/login";
  }
  dispatch({ type: "FETCH_ORDERS", payload: response.data });
};

//FETCH CANCELLED ORDERS

export const fetchCancelledOrders = (page, employee_id, branch_id) => async (dispatch) => {
  //FETCHING TOKEN AND ADDING IT TO CONFIG
  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  //FETCHING THE CANCELLED ORDER LIST
  const response = await orders.get(
    `/get_emp_cancelled_orders/${page}?employee=${employee_id}&branch=${branch_id}`,
    config
  );
  //REDIRECTING TO LOGIN PAGE IF THE TOKEN CHANGES
  if (response.data.data === "Token does not match") {
    sessionStorage.clear();
    window.location.href = "/login";
  }
  //DISPATCHING THE FETCHED LIST
  dispatch({ type: "FETCH_CANCELLED_ORDERS", payload: response.data });
};

//FETCH PREPARING ORDERS

export const fetchPreparingOrders = (page, employee_id, branch_id) => async (dispatch) => {
  //GETTING THE TOKEN AND ADDING IT TO CONFIG
  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  //FETCHING THE PREPARING ORDERS LIST
  const response = await orders.get(
    `/get_emp_preparing_orders/${page}?employee=${employee_id}&branch=${branch_id}`,
    config
  );
  //REDIRECTING TO LOGIN PAGE IF THE TOKEN CHANGES
  if (response.data.data === "Token does not match") {
    sessionStorage.clear();
    window.location.href = "/login";
  }
  //DISPATCHING THE FETCHED ORDER LIST
  dispatch({ type: "FETCH_PREPARING_ORDERS", payload: response.data });
};

//FETCH SINGLE ORDERS

export const fetchSingleOrder = (order) => async (dispatch) => {
  //FETCHING THE TOKEN AND ADDING IT TO CONFIG
  const token = get(store.getState(), "userLogin.userInfo.token");
  const config = {
    headers: {
      token: token,
    },
  };
  //GETTING THE ID FROM ORDER PROP
  const id = order?.order_id;
  //FETCHING THE SINGLE ORDER DATA
  const response = await orders.get(`/fetch_single_order?order=${id}`, config);
  //REDIRECTING TO LOGIN PAGE IF THE TOKEN CHANGES
  if (response.data.data === "Token does not match") {
    sessionStorage.clear();
    window.location.href = "/login";
  }
  //DISPATCHING THE RESPONSE
  dispatch({ type: "FETCH_SINGLE_ORDER", payload: response.data });
};

//LOADERS

export const showLoader = () => (dispatch) => {
  dispatch({ type: "SHOW_LOADER" });
};
export const hideLoader = () => (dispatch) => {
  dispatch({ type: "HIDE_LOADER" });
};
