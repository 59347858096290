import { createContext, useContext, useState } from "react";

const appContext = createContext({});

export const useAppContext = () => useContext(appContext);

export const AppContextProvider = ({ children }) => {
  const [startFunction, setStartFunction] = useState(null);
  const [duration, setDuration] = useState(0);
  return (
    <appContext.Provider
      value={{ duration, setDuration, startFunction, setStartFunction }}
    >
      {children}
    </appContext.Provider>
  );
};
