import {
  LOGOUT_REPORTS_REQUEST,
  LOGOUT_REPORTS_SUCCESS,
  LOGOUT_REPORTS_FAIL,
} from "../constants/logoutReportsConstants";

export const logoutReportsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGOUT_REPORTS_REQUEST:
      return {
        loading: true,
        logoutReports: [],
      };

    case LOGOUT_REPORTS_SUCCESS:
      return {
        loading: false,
        logoutReports: action.payload,
      };

    case LOGOUT_REPORTS_FAIL:
      return {
        loading: false,
        errorlogoutReports: action.payload,
      };
    default:
      return state;
  }
};
