import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-[100vh]">
      <div className="coffee-mug">
        <div className="coffee-container">
          <div className="coffee"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
