import React, { useState } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import orders from "../../api/orders";

const NewCustomerSidebar = ({
  isOpenNewCustomerSidebar,
  setIsOpenNewCustomerSidebar,
  setCustomerInfoRenderDta,
  addNumber,
  setAddNumber
}) => {

  const ToggleNewCustomerSidebar = () => {
    setAddNumber(false);
    setData({
      ...data,
      mobileno: ""
    });
    isOpenNewCustomerSidebar === true
      ? setIsOpenNewCustomerSidebar(false)
      : setIsOpenNewCustomerSidebar(true);
  };

  const FEMALE_VALUE = "female";
  const MALE_VALUE = "male";

  const [selectedGender, setSelectedGender] = useState("");
  const [useSameAddress, setUseSameAddress] = useState(false);

  const alert = useAlert();

  const branchIdfromStore = useSelector(
    (state) => state.userDetails?.user?.branch_id
  );
  const branchfromStore = useSelector(
    (state) => state.userDetails?.user?.branch
  );

  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    profilepic: "",
    mobileno: "",
    email: "",
    dateofbirth: null,
    gender: selectedGender,

    branch: branchfromStore,
    branchid: branchIdfromStore,
    shippingaddress: "",
    shippincode: "",

    billingaddress: "",
    billpincode: "",
    shippingDistrict: "",
    shippingState: "",
    billingDistrict: "",
    billingState: ""
  });

  if (addNumber && data.mobileno === "") {
    setData({
      ...data,
      mobileno: addNumber
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const OnchangeToggle = (e) => {
    setUseSameAddress(e.target.checked);

    if (e.target.checked) {
      setData({
        ...data,
        billingaddress: data.shippingaddress,
        billpincode: data.shippincode,
        billingDistrict: data.city,
        billingState: data.state,
      });
    } else {
      setData({
        ...data,
        billingaddress: "",
        billpincode: "",
      });
    }
  };

  const submitHandler = () => {
    // alert.success('customer added successfully');
    //e.preventDefault();

    const config = { headers: { "Content-Type": "application/json" } };

    orders
      .post(
        "/new_customer",
        {
          first_name: data.firstname,
          last_name: data.lastname,
          profile_pic: data.profilepic,
          mobile_no: data.mobileno,
          email: data.email,
          date_of_birth: data.dateofbirth,
          gender: selectedGender,
          branch: branchfromStore,
          branch_id: branchIdfromStore,
          shipping_address: {
            address: data.shippingaddress,
            pincode: data.shippincode,
          },
          billing_address: {
            address: data.billingaddress,
            pincode: data.billpincode,
          },
        },
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("customer added successfully!");
          sessionStorage.setItem(
            "customerInfo",
            JSON.stringify(res.data.customer)
          );

          setIsOpenNewCustomerSidebar(false);
          setCustomerInfoRenderDta(res.data.customer);
          setData({
            ...{
              firstname: "",
              lastname: "",
              profilepic: "",
              mobileno: "",
              email: "",
              dateofbirth: "",

              shippingaddress: "",
              shippincode: "",

              billingaddress: "",
              billpincode: "",
            },
          });
          setSelectedGender("");
          setUseSameAddress(false);
          return;
        }
        if (res.data.status === "failure") {
          alert.error(`${res.data.msg}`);
          return;
        }
      })
      .catch((error) => {
      });
  };







  return (
    <>
      <div
        className={`sidebar-new-customer p-2 ${
          isOpenNewCustomerSidebar === true ? "active" : ""
        }`}
      >
        <div className="sidebar-header p-1">
          <div className="flex justify-between items-center">
            <h6 className="text-gray-800 font-bold text-lg leading-tight uppercase">
              ADD NEW CUSTOMER
            </h6>
            <button
              className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
              onClick={ToggleNewCustomerSidebar}
            >
              <XIcon className="h-6 w-6 close-icon" />
            </button>
          </div>
        </div>
        <div className="p-1 w-full overflow-auto sidebar-new-customer-div">
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-primaryColor bg-primaryLightColor25 rounded-lg hover:bg-primaryLightColor30 mb-2">
                  <span>Customer Information</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-primaryColor`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-2 pb-2 ">
                  <div className="w-full flex gap-2 mb-2">
                    <input
                      type="text"
                      className="w-1/2 p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="First Name"
                      autoFocus
                      required
                      name="firstname"
                      value={data.firstname}
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="w-1/2 p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Last Name"
                      name="lastname"
                      value={data.lastname}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <input
                      type="text"
                      className="w-full p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Mobile Number"
                      required
                      name="mobileno"
                      value={data.mobileno}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <input
                      type="email"
                      className="w-full p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Email Address"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <input
                      type="text"
                      className="w-full p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Date Of Birth"
                      name="dateofbirth"
                      value={data.dateofbirth || ""}
                      onChange={handleChange}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <div className="my-2 mr-5 flex flex-row">
                      <input
                        className="form-check-input appearance-none rounded-full h-4 w-4 border border-black bg-white checked:bg-primaryColor/50  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={selectedGender === MALE_VALUE}
                        onChange={(e) => {
                          if (e.target.checked) setSelectedGender(MALE_VALUE);
                        }}
                      />
                      <p>Male</p>
                    </div>
                    <div className="flex flex-row">
                      <input
                        className="form-check-input appearance-none rounded-full h-4 w-4 border border-black bg-white checked:bg-primaryColor/50  focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        value={selectedGender === FEMALE_VALUE}
                        onChange={(e) => {
                          if (e.target.checked) setSelectedGender(FEMALE_VALUE);
                        }}
                      />
                      <p>Female</p>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-primaryColor bg-primaryLightColor25 rounded-lg hover:bg-primaryLightColor30 mb-2">
                  <span>Shipping Address</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-primaryColor`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-2 pb-2">
                  <div className="w-full mb-2">
                    <input
                      type="text"
                      className="w-full p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Address"
                      name="shippingaddress"
                      value={data.shippingaddress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex gap-2 mb-2">
                    <input
                      type="text"
                      className="w-2/6 p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Pin Code"
                      name="shippincode"
                      value={data.shippincode}
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="w-4/6 p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="District/City"
                      // value={data.city.value}
                      onChange={handleChange}
                      name="city"
                    />
                  </div>
                  <div className="w-full mb-2">
                    <input
                      type="text"
                      className="w-full p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="State"
                      // value={data.state.value}
                      name="state"
                      onChange={handleChange}
                    />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <div className="flex items-center justify-start w-full mb-2">
            <label
              htmlFor="toggle"
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle"
                  className="sr-only"
                  onChange={OnchangeToggle}
                  value={useSameAddress}
                />

                <div className="block bg-primaryLightColor25 w-7 h-4 rounded-full"></div>

                <div className="dot absolute left-0.5 top-0.5 bg-primaryColor/50 w-3 h-3 rounded-full transition"></div>
              </div>

              <div className="ml-2 text-gray-700 font-medium">
                Same as Shipping Address
              </div>
            </label>
          </div>
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-primaryColor bg-primaryLightColor25 rounded-lg hover:bg-primaryLightColor30 mb-2">
                  <span>Billing Address</span>
                  <ChevronUpIcon
                    className={`${
                      open ? "transform rotate-180" : ""
                    } w-5 h-5 text-primaryColor`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-2 pb-2">
                  <div className="w-full mb-2">
                    <input
                      type="text"
                      name="billingaddress"
                      className="w-full p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Address"
                      value={data.billingaddress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex gap-2 mb-2">
                    <input
                      type="text"
                      className="w-2/6 p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="Pin Code"
                      value={data.billpincode}
                      onChange={handleChange}
                    />
                    <input
                      type="text"
                      className="w-4/6 p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="District/City"
                      value={data.billingDistrict}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full mb-2">
                    <input
                      type="text"
                      className="w-full p-2 text-sm font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70"
                      placeholder="State"
                      value={data.billingState}
                      onChange={handleChange}
                    />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <div className="w-full mt-2">
          <button
            className="w-full px-4 py-2 text-sm font-medium text-white bg-primaryColor rounded-lg hover:bg-primaryDarkColor"
            onClick={() => {
              if (data.firstname === "" || selectedGender === "") {
                alert.error("Please enter First Name & Gender");
              } else {
                submitHandler();
              }
            }}
          >
            Save Customer Information
          </button>
        </div>
      </div>

      <div
        className={`sidebar-new-customer-overlay ${
          isOpenNewCustomerSidebar === true ? "active" : ""
        }`}
      ></div>
    </>
  );
};

export default NewCustomerSidebar;
