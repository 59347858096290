import React, { useEffect, useState } from "react";
import { XCircleIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { removeItemsFromCart } from "../../actions/cartAction";
import Modal from "../product/Modal";
const RightDivCartItem = ({ item, discount, setDiscount, subTotalAmount }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  // console.log(discount, 'discount')

  const deleteCartItems = (sku) => {
    //dispatch(removeItemsFromCart(sku));
    //if (item.product.product_add_from === 'coupon') {
    //dispatch(setDiscount(null));
    //dispatch(removeItemsFromCart(checkWhetherProductIsDiscounted[0]));
    //}
    dispatch(removeItemsFromCart(sku));
  };


  const objectToArray = (obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    let arr = [];
    keys.forEach((key, i) => {
      let val = values[i];
      let desc = "";
      val.forEach((obj, index) => {
        if (index === val.length - 1) desc += obj.value;
        else desc += (obj.value + ", ");
      })
      let obj = {
        title: key,
        desc: desc
      };
      arr.push(obj);
    })
    return arr;
  }
  let adds = objectToArray(item.add_ons);
  return (
    <div>
      <div className="product-card-item p-1.5 bg-white shadow-sm rounded-[10px] mb-1.5 cursor-pointer" onClick={() => setIsOpen(true)}>
        <div className="flex justify-between items-center gap-2">
          <div className="flex gap-2">
            <div className="flex-none relative w-[60px] h-[60px] rounded-[10px] cursor-pointer">
              <img
                src={item?.image}
                alt={item?.name}
                className="w-full h-full rounded-[10px] relative object-cover"
              />
              <span className="text-[9px] rounded-full bg-primaryColor text-white p-0.1 px-1 absolute top-[-5px] right-[-5px]">
                {item?.qty}
              </span>
            </div>
            <div
              className="cursor-pointer"
            >
              <h6 className="font-bold text-gray-800 capitalize text-md leading-tight text-[15px]">
                {item?.name}
              </h6>
              <h6 className="text-gray-400 leading-tight capitalize text-sm">
                {/* &#8377;{item?.price}&nbsp; */}
                {adds.map((addon, i) => <span className="mr-1" key={i}>{/* {addon.title}: */} {addon.desc}{i !== adds.length - 1 && ', '}</span>)}
                {/* {Object.entries(item?.add_ons || {}).map(([key, val], i) => (
                  <span className="mr-1" key={i}>
                    {key}: {val}
                    {i !== Object.entries(item?.add_ons || {}).length - 1
                      ? ", "
                      : ""}
                  </span>
                ))} */}
                {item.note !== "" && (
                  <span className="mr-1">{/* Note: */}, {item.note}</span>
                )}
              </h6>
            </div>
          </div>
          <div className="flex gap-2">
            <h6 className="font-bold text-md text-gray-700 leading-none">
              <span className="">
                &#8377;
                {Number(item.price + item.add_on_price) *
                  item.qty}
              </span>
            </h6>
            <button
              className="text-gray-600 hover:text-gray-800"
              onClick={() => deleteCartItems(item?.sku)}
            >
              <XCircleIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
      { }
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} product={item.product} edit={true} editItem={item} />
    </div>
  );
};

export default RightDivCartItem;
