import { XIcon } from "@heroicons/react/outline";
import React from "react";
// import { useSelector } from "react-redux";
import OrderItemsSidebarCard from "./OrderItemsSidebarCard";
import Moment from "react-moment";
import 'moment-timezone';

import Nodata from "./Nodata";
// import Loader from "./Loader";

const OrderItemsSidebar = ({
  isOpenOrderItemsSidebar,
  setIsOpenOrderItemsSidebar,

  orderDetails,
}) => {
  // const { loading } = useSelector((state) => state.lastCustomerOrder);

  const ToggleOrderItemsSidebar = () => {
    isOpenOrderItemsSidebar === true
      ? setIsOpenOrderItemsSidebar(false)
      : setIsOpenOrderItemsSidebar(true);
  };


  const LastCustomerordersList = orderDetails?.order_items || [];

  // const renderCard = () => {
  //   if (lastCustomerOrders?.order_items?.length > 0) {
  //     return lastCustomerOrders?.order_items.map((orderitem, i) => {
  //       return <OrderItemsSidebarCard key={i} order_item={orderitem} />;
  //     });
  //   } else if (loading === true) {
  //     return <Loader />;
  //   } else {
  //     return <Nodata />;
  //   }
  // };

  // const addToCartHandler = () => {
  //   const id = product.product_id;
  //   const sku =
  //     product.product_list.sku + "-" + Object.values(addOnSKU).join("-");
  //   let count = 0;
  //   if (cartItems.length === 0) {
  //     dispatch(
  //       addItemsToCart(
  //         sku,
  //         id,
  //         qty,
  //         addOn,
  //         addOnPrice,
  //         food_type,
  //         prepare_time,
  //         product_type,
  //         addComment
  //       )
  //     );
  //   } else {
  //     for (let i = 0; i < cartItems.length; i++) {
  //       if (sku === cartItems[i].sku) {
  //         count = 1;
  //       }
  //     }
  //     if (count === 0) {
  //       dispatch(
  //         addItemsToCart(
  //           sku,
  //           id,
  //           qty,
  //           addOn,
  //           addOnPrice,
  //           food_type,
  //           prepare_time,
  //           product_type,
  //           addComment
  //         )
  //       );
  //       alert.success("Added the item to the cart");
  //     } else {
  //       alert.info("Item is Already in the cart");
  //     }
  //   }

  //   setAddOn({});
  //   setAddOnPrice({});
  //   setAddOnSKU({});
  //   setAddComment("");
  //   setQty(1);
  // };



  return (
    <>
      <div
        className={`sidebar-order-items p-2 ${isOpenOrderItemsSidebar === true ? "active" : ""
          }`}
      >
        <div className="sidebar-header p-1 mb-2">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-gray-800 font-bold text-lg leading-tight uppercase">
                {orderDetails?.order_id}
              </h6>
              <Moment tz="Asia/Kolkata" format="MMM D, YYYY hh:mm a" className="font-bold text-gray-500 leading-tight text-xs lining-nums">
                {orderDetails?.createdAt}
              </Moment>
            </div>
            <button
              className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
              onClick={ToggleOrderItemsSidebar}
            >
              <XIcon className="h-6 w-6 close-icon" />
            </button>
          </div>
        </div>
        <div className="sidebar-body p-1 ">
          {LastCustomerordersList.length === 0 ? (
            <Nodata />
          ) : (
            LastCustomerordersList &&
            LastCustomerordersList.map((item, i) => {
              return (
                // <div key={i} className="w-full overflow-auto  pr-1">
                //   <div className="flex items-center justify-between gap-2 p-1.5 bg-white rounded-[10px] mb-2 shadow-sm border-2 border-primaryColor/10">
                //     <div className="flex items-center gap-2">
                //       <div className="flex-none relative w-[60px] h-[60px] rounded-[10px]">
                //         <img
                //           src="https://source.unsplash.com/random/900x900"
                //           alt="product"
                //           className="w-full h-full rounded-[10px] relative"
                //         />
                //         <span className="text-[9px] rounded-full bg-primaryColor text-white p-0.1 px-1 absolute top-[-5px] right-[-5px]">
                //           {item.quantity}
                //         </span>
                //       </div>
                //       <div>
                //         <h6 className="font-bold text-gray-700 text-md leading-tight">
                //           {item.product_name}
                //         </h6>
                //         <h6 className="font-bold text-gray-600 text-xs lining-nums leading-tight">
                //           ₹{item.total_price}
                //         </h6>
                //         <Moment className="font-bold text-gray-500 text-xs lining-nums leading-tight">
                //           {item.createdAt}
                //         </Moment>
                //       </div>
                //     </div>
                //     <button
                //       className="text-primaryColor hover:text-primaryDarkColor"
                //       onClick={""}
                //     >
                //       <ShoppingCartIcon className="w-5 h-5" />
                //     </button>
                //   </div>
                // </div>
                <OrderItemsSidebarCard key={i} order_item={item} setIsOpenOrderItemsSidebar={setIsOpenOrderItemsSidebar} />
              );
            })
          )}
        </div>
      </div>
      <div
        className={`sidebar-order-items-overlay ${isOpenOrderItemsSidebar === true ? "active" : ""
          }`}
      ></div>
    </>
  );
};

export default OrderItemsSidebar;
