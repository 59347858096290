import React, { useEffect, useState } from "react";
import orders from "../../api/orders";
import store from "../../store";
import { get } from "lodash";
const objectToArray = (obj) => {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  let arr = [];
  keys.forEach((key, i) => {
    let val = values[i];
    if (Array.isArray(val) === false) val = val.split(', ');
    let desc = "";
    val.forEach((obj, index) => {
      if (index === val.length - 1) desc += obj.value;
      else desc += (obj.value + ", ");
    })
    let obj = {
      title: key,
      desc: desc
    };
    arr.push(obj);
  })
  return arr;
}
const ProductCard = ({ order_item }) => {
  // console.log(order_item);
  useEffect(() => {
    getImage(order_item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order_item]);
  const [image, setImage] = useState(null);
  const getImage = async (order_item) => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: {
        token: token,
      },
    };
    const product = order_item.product_id;
    const response = await orders.get(
      `get_single_product?product=${product}`,
      config
    );
    if (response.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }
    setImage(response);
  };
  let adds = objectToArray(order_item.add_ons);
  if (!image) {
    return <div></div>;
  }
  return (
    <li
      className="product-card-item p-1 bg-stone-50 shadow-sm rounded-[10px] mb-1.5 "
      key={order_item.order_id}
    >
      <div className="flex w-full gap-2">
        <div className="flex-none relative w-[60px] h-[60px] rounded-[10px] cursor-pointer ">
          <img
            alt="orderitempic"
            className="w-full h-full rounded-[10px] object-cover"
            src={image.data.data?.product_list?.card_img}
          />
          <span className="text-[11px] rounded-full bg-primaryColor text-stone-50 p-0.1 px-1 absolute top-[-1px] right-[-4px]">
            {order_item?.quantity || "1"}
          </span>
        </div>

        <div>
          <h2 className="font-bold text-gray-800 capitalize text-md line-clamp-1 leading-tight cursor-pointer">
            {order_item?.product_name}
          </h2>
          <div className="font-bold text-sm text-gray-500  leading-tight line-clamp-2 capitalize truncate">
            <div className="flex">
              <p>Food Type: {order_item.food_type}</p> &nbsp;
              {order_item.comment && <p>Note: {order_item.comment}</p>}
            </div>
            {adds.map((addon, index) => <span className="mr-1" key={index}>{addon.desc}{(index !== (adds.length - 1)) && ', '}</span>)}
            {/* {Object.entries(order_item.add_ons).map(([key, val], i) => (
              <span className="mr-1" key={i}>
                {key}: {val}
                {i !== Object.entries(order_item.add_ons).length - 1
                  ? ", "
                  : ""}
              </span>
            ))} */}
          </div>
        </div>
        <div className="flex justify-around items-center gap-2 pr-2 ml-auto">
          <h6 className="font-bold text-md text-gray-500 leading-none">
            ₹{Math.round((order_item.total_price + order_item.add_ons_price) * 100) / 100}
          </h6>
        </div>
      </div>
    </li>
  );
};

export default ProductCard;
