import React from "react";
import Moment from "react-moment";
import 'moment-timezone';
import { useSelector } from "react-redux";
import { ReactComponent as NavIcon } from "../../assets/images/heebeeIcon.svg";

const LogoutReportInvoice = ({ componentref, orderData, data }) => {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const branchNameFromStore = useSelector(
    (state) => state.userDetails?.user?.branch
  );
  // console.log(data);
  return (
    <div className="p-[5px]" ref={componentref}>
      {/* <div className="flex justify-between items-center mb-5">
        <h3 className="font-bold text-sm">
          <Moment format="Do MMM">
            {today}
          </Moment>
        </h3>
        <h3 className="font-bold text-sm">
          <Moment format="YYYY">
            {today}
          </Moment>
        </h3>
      </div> */}
      <div className="flex flex-col justify-center items-center mt-4">
        <div className="flex justify-center items-end relative">
          <NavIcon className="w-20 h-20" />
          <span className="absolute bottom-1 right-0">&#174;</span>
        </div>
        <h3 className="text-lg font-bold uppercase">HeeBee Coffee Private Limited</h3>
        <h3 className="text-md font-bold">
          {/* <Moment tz="Asia/Kolkata" format="Do MMM, YYYY">
            {today}
          </Moment> */}
          <Moment tz="Asia/Kolkata" format="MMM D, YYYY">{data.login_in_time}</Moment>  - <Moment tz="Asia/Kolkata" format="MMM D, YYYY">{data.logout_in_time}</Moment>
        </h3>
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          {data.branch_name}
        </h3>
      </div>
      <div className="flex justify-between items-center mb-10">
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          {data.employee_name}
        </h3>
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          <Moment tz="Asia/Kolkata" format="hh:mm A">
            {data.login_in_time}
          </Moment> - <Moment tz="Asia/Kolkata" format="hh:mm A">
            {data.logout_time}
          </Moment>
        </h3>
      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Cash Sale Report
        </h3>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total Cash collection          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_cash_collection * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Sole Cash sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.sole_cash_sales * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Cash + wallet
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.cash_plus_wallet * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Cash + card
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.cash_plus_card * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Wallet additions through cash
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.wallet_add_cash * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Petty cash in begining
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.petty_cash * 100) / 100 || 0}
          </h3>
        </div>

      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Digital Sale Report
        </h3>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total Digital Collection
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_digital_collection * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Sole card sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.sole_card_sales * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Card + cash
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.card_plus_cash * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Card + wallet
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.card_plus_wallet * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Wallet additions through card
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.wallet_add_card * 100) / 100 || 0}
          </h3>
        </div>


      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Wallet Collection Report
        </h3>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total Wallet Collection
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_wallet_collection * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Wallet addition through cash
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.wallet_add_cash * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Wallet addition through card
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.wallet_add_card * 100) / 100 || 0}
          </h3>
        </div>


      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        {/* <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Wallet Spending Report
        </h3> */}

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total wallet deduction/sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_wallet_deduction_sales * 100) / 100 || 0}
          </h3>
        </div>

        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Total delievery partner sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_delievery_partner_sales * 100) / 100 || 0}
          </h3>
        </div>



      </div>

      <div className="flex flex-col justify-center items-center  border-y-2 border-black border-dashed mb-4">
        {/* <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Digital Account Report
        </h3> */}
        <div className="flex justify-between items-center w-full px-1 py-1/2">
          <h3 className="text-xs font-bold capitalize">
            Delivery Charge
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_account_delivery_charges * 100) / 100 || 0}
          </h3>
        </div>
        <div className="flex justify-between items-center w-full px-1 py-1  border-black border-dashed">
          <h3 className="text-xs font-bold capitalize">
            total sales
          </h3>
          <h3 className="text-xs font-bold">
            &#8377;{Math.round(data.total_sales * 100) / 100 || 0}
          </h3>
        </div>


      </div>

      <div className="flex justify-between items-center  border-t-2 border-black  px-1 py-1/2">
        <h3 className="text-md font-bold uppercase ">
          Net total POS sales
        </h3>
        <h3 className="text-md font-bold uppercase">
          &#8377;{Math.round(data.net_total_pos_sales * 100) / 100 || 0}
        </h3>
      </div>

      <div className="flex justify-between items-center  border-y-2 border-black  px-1 py-1/2">
        <h3 className="text-md font-bold uppercase ">
          Net total POS cash sales        </h3>
        <h3 className="text-md font-bold uppercase">
          &#8377;{Math.round(data.net_total_pos_cash_sales * 100) / 100 || 0}
        </h3>
      </div>

      <div className="flex justify-between items-center  border-b-2 border-black  px-1 py-1/2">
        <h3 className="text-md font-bold uppercase ">
          Net digital sales collection        </h3>
        <h3 className="text-md font-bold uppercase">
          &#8377;{Math.round(data.net_digital_sales_collection * 100) / 100 || 0}
        </h3>
      </div>

      <div className="flex justify-between items-center  border-b-2 border-black  px-1 py-1/2">
        <h3 className="text-md font-bold uppercase ">
          Net status of wallet         </h3>
        <h3 className="text-md font-bold uppercase">
          &#8377;{Math.round(data.net_status_of_wallet * 100) / 100 || 0}
        </h3>
      </div>

      <div className="flex justify-between items-center  border-b-2 border-black mb-4 px-1 py-1/2">
        <h3 className="text-md font-bold uppercase ">
          Net Total Delivery Sales       </h3>
        <h3 className="text-md font-bold uppercase">
          &#8377;{Math.round(data.net_total_delievery_sales * 100) / 100 || 0}
        </h3>
      </div>

      <div className="flex justify-between items-center mt-16 mb-5">
        <h3 className="text-md font-bold capitalize border-t-[2px] border-dashed border-black">
          Employee Sign.
        </h3>
        <h3 className="text-md font-bold capitalize border-t-[2px] border-dashed border-black">
          Manager Sign.
        </h3>
      </div>

    </div>
  );
};

export default LogoutReportInvoice;
