import React, { useCallback, useEffect, useState } from "react";
import Filter from "../components/layout/Filter";
import { FiShoppingCart } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MenuAlt2Icon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/outline";
import { connect, useSelector } from "react-redux";
import orders from "../api/orders";

import {
  fetchOrders,
  fetchCancelledOrders,
  fetchPreparingOrders,
  fetchSingleOrder,
  fetchAllOrders,
  showLoader,
  hideLoader,
} from "../actions/orderListAction";
import OrderDetail from "../components/home/OrderDetail";
import OrdersList from "../components/home/OrdersList";
import { useNavigate } from "react-router-dom";
import Nodata from "../components/home/Nodata";
import store from "../store";
import { get } from "lodash";
import Sidebar from "../components/layout/Sidebar";
import Notification from "../components/layout/Notification";
import Loader from "../components/home/Loader";

const OrderHistory = ({
  fetchOrders,
  fetchCancelledOrders,
  fetchPreparingOrders,
  fetchSingleOrder,
  completedOrders,
  cancelledOrders,
  allOrders,
  fetchAllOrders,
  preparingOrders,
  singleOrder,
  showLoader,
  hideLoader,
}) => {
  const [visible, setVisible] = React.useState(true);
  const [filter, setFilter] = useState("ALL_ORDERS");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalItemsLength, setTotalItemsLength] = useState(0);

  const employee_id = useSelector(
    (state) => state.userDetails?.user?.employee_id
  );
  const branch_id = useSelector((state) => state.userDetails?.user?.branch_id);
  // get userinfo from redux store
  const userLogin = useSelector((state) => state.userLogin);
  const userBranch2 = useSelector((state) => state.userBranch);
  const verifyBranch2 = useSelector((state) => state.verifyBranch);
  const { userInfo } = userLogin;
  const { userBranch } = userBranch2;
  const { verifyBranch } = verifyBranch2;

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextClick = useCallback(() => {
    setCurrentPage((v) => v + 1);
  }, []);
  const handlePrevClick = useCallback(() => {
    setCurrentPage((v) => v - 1);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (branch_id !== undefined && employee_id !== undefined) {
      fetchAllOrders(currentPage, employee_id, branch_id);
      fetchPreparingOrders(currentPage, employee_id, branch_id);
      fetchOrders(currentPage, employee_id, branch_id);
      fetchCancelledOrders(currentPage, employee_id, branch_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, employee_id, branch_id]);

  useEffect(() => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchOrders = async (searchTerm) => {
    const token = get(store.getState(), "userLogin.userInfo.token");

    const config = {
      headers: {
        token: token,
      },
    };
    const response = await orders.get(
      `/search_order/${currentPage}?search=${searchTerm}`,
      config
    );
    if (response.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }

    setSearchData(response);
  };

  const ToggleMainSidebar = () => {
    setIsOpenMainSidebar(!isOpenMainSidebar);
  };

  const onOrderSelect = (order) => {
    showLoader();
    fetchSingleOrder(order);
    setTimeout(() => {
      hideLoader();
    }, 2000);
  };

  const onPreparingClick = () => {
    setCurrentPage(1);
    setSearchTerm("");
    setSearchData(null);
    showLoader();
    setFilter("PREPARING_ORDERS");
    fetchPreparingOrders(currentPage, employee_id, branch_id);
    onOrderSelect(preparingOrders.data?.[0]);

    // setTotalItemsLength(preparingOrders?.data?.length);
    setTimeout(() => {
      hideLoader();
    }, 1000);
  };
  const onCompletedClick = () => {
    setCurrentPage(1);
    setSearchTerm("");
    setSearchData(null);
    showLoader();
    setFilter("COMPLETED_ORDERS");
    fetchOrders(currentPage, employee_id, branch_id);
    onOrderSelect(completedOrders.data?.[0]);
    // setTotalOrders(completedOrders.total_orders);
    // setTotalItemsLength(completedOrders.data?.length);
    setTimeout(() => {
      hideLoader();
    }, 1000);
  };

  const onAllOrderClick = () => {
    setCurrentPage(1);
    setSearchTerm("");
    setSearchData(null);
    showLoader();
    setFilter("ALL_ORDERS");

    fetchAllOrders(currentPage, employee_id, branch_id);

    onOrderSelect(allOrders.data?.[0]);
    // setTotalOrders(allOrders.total_orders);

    setTimeout(() => {
      hideLoader();
    }, 1000);
  };

  // const onAllOrderClick = () => {
  //   setCurrentPage(1)
  //   setSearchTerm("");
  //   setSearchData(null);
  //   showLoader();
  //   setFilter("ALL_ORDERS");

  //   fetchAllOrders(currentPage, employee_id, branch_id);

  //   onOrderSelect(allOrders.data?.[0]);
  //   setTotalOrders(allOrders.total_orders);
  //   setTotalItemsLength(allOrders.data?.length);
  //   setTimeout(() => {
  //     hideLoader();
  //   }, 1000);
  // };

  const onCancelledClick = () => {
    setCurrentPage(1);
    setSearchTerm("");
    setSearchData(null);
    showLoader();
    setFilter("CANCELLED_ORDERS");
    fetchCancelledOrders(currentPage, employee_id, branch_id);
    onOrderSelect(cancelledOrders?.data?.[0]);
    // setTotalOrders(cancelledOrders.total_orders);
    // setTotalItemsLength(cancelledOrders.data?.length);
    setTimeout(() => {
      hideLoader();
    }, 1000);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setFilter("SEARCH_FILTER");
    searchOrders(searchTerm);
  };

  const searchOutputFunction = () => {
    if (searchData.data.status === "success") {
      return (
        <OrdersList
          ordersList={searchData.data}
          onOrderSelect={onOrderSelect}
        />
      );
    } else if (searchData.data.status === "failure") {
      return <Nodata height={100} />;
    }
  };

  useEffect(() => {
    if (searchData?.data.status === "success") {
      setTotalOrders(searchData?.data?.total_orders);
      setTotalItemsLength(searchData?.data?.data.length);
    } else if (searchData?.data.status === "failure") {
      setTotalOrders(0);
      setTotalItemsLength(0);
      return <Nodata height={100} />;
    }
  }, [searchData]);

  const outputFunction = () => {
    if (filter === "ALL_ORDERS") {
      if (allOrders.data) {

        return (
          <OrdersList ordersList={allOrders} onOrderSelect={onOrderSelect} />
        );
      } else if (allOrders.status === "failure") {
        return <Nodata height={100} />;
      } else {
        return (
          <div className="w-full h-10 bg-stone-50 px-2 py-2 box-border rounded-[10px]">
            {" "}
          </div>
        );
      }
    } else if (filter === "COMPLETED_ORDERS") {
      if (completedOrders.data) {
        return (
          <OrdersList
            ordersList={completedOrders}
            onOrderSelect={onOrderSelect}
          />
        );
      } else if (completedOrders.status === "failure") {
        return <Nodata height={100} />;
      } else {
        return (
          <div className="w-full h-10 bg-stone-50 px-2 py-2 box-border rounded-[10px]"></div>
        );
      }
    } else if (filter === "PREPARING_ORDERS") {
      if (preparingOrders.data) {
        return (
          <OrdersList
            ordersList={preparingOrders}
            onOrderSelect={onOrderSelect}
          />
        );
      } else if (preparingOrders.status === "failure") {
        return <Nodata height={100} />;
      } else {
        return (
          <div className="w-full h-10 bg-stone-50 px-2 py-2 box-border rounded-[10px]"></div>
        );
      }
    } else if (filter === "CANCELLED_ORDERS") {
      if (cancelledOrders.data) {
        return (
          <OrdersList
            ordersList={cancelledOrders}
            onOrderSelect={onOrderSelect}
          />
        );
      } else if (cancelledOrders.status === "failure") {
        return <Nodata height={100} />;
      } else {
        return (
          <div className="w-full h-10 bg-stone-50 px-2 py-2 box-border rounded-[10px]"></div>
        );
      }
    }
  };

  useEffect(() => {
    if (filter === "ALL_ORDERS") {
      if (allOrders.status === "success") {
        setTotalItemsLength(allOrders?.data?.length);
        setTotalOrders(allOrders?.total_orders);
      } else if (allOrders.status === "failure") {
        setTotalItemsLength(0);
        setTotalOrders(0);
      }
    }
    if (filter === "COMPLETED_ORDERS") {
      if (completedOrders.status === "success") {
        setTotalItemsLength(completedOrders.data?.length);
        setTotalOrders(completedOrders?.total_orders);
      } else if (completedOrders.status === "failure") {
        setTotalItemsLength(0);
        setTotalOrders(0);
      }
    }
    if (filter === "PREPARING_ORDERS") {
      if (preparingOrders.status === "success") {
        setTotalItemsLength(preparingOrders.data?.length);
        setTotalOrders(preparingOrders?.total_orders);
      } else if (preparingOrders.status === "failure") {
        setTotalItemsLength(0);
        setTotalOrders(0);
      }
    }
    if (filter === "CANCELLED_ORDERS") {
      if (cancelledOrders.status === "success") {
        setTotalItemsLength(cancelledOrders.data?.length);
        setTotalOrders(cancelledOrders?.total_orders);
      } else if (cancelledOrders.status === "failure") {
        setTotalItemsLength(0);
        setTotalOrders(0);
      }
    }
    if (filter === "SEARCH_FILTER") {
      searchOrders(searchTerm);
    }
  }, [filter, allOrders, completedOrders, preparingOrders, cancelledOrders]);

  useEffect(() => {
    if (searchTerm === '') onAllOrderClick();
  }, [searchTerm])


  const totalPageCount = Math.ceil(totalOrders / 10) || 0;

  const disablePrevButton = currentPage === 1;
  const disableNextButton = currentPage === totalPageCount || totalOrders === 0;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (!userBranch) {
      navigate("/select-branch");
    } else if (!verifyBranch) {
      navigate("/branch-verify");
    }
  }, [userInfo, navigate, userBranch, verifyBranch]);


  return (
    <>
      <div>
        <button
          className=" font-bold  py-2 px-4  leading-normal sm:hidden bg-white/50 hover:bg-primaryLightColor25 text-primaryColor p-2 rounded-[10px] backdrop-blur-[3px] block md:hidden shadow fixed bottom-[60px] right-[20px]  z-50"
          onClick={() => setVisible(!visible)}
        >
          {visible ? (
            <FiShoppingCart className="text-3xl" />
          ) : (
            <MdOutlineCancel className="text-4xl" />
          )}
        </button>
      </div>

      <div className="bg-stone-200 h-screen flex flex-col md:flex-row">
        <Loader />
        {/* left */}
        <section
          className={`left-section w-full md:w-[700px] h-full flex flex-col${visible ? " active" : ""
            }`}
        >
          <div className=" flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] m-2 p-1">
            <div className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] cursor-pointer">
              <ChevronLeftIcon
                className="h-6 w-6"
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>

            <div className=" flex-end w-64 ">
              <h1 className="text-2xl text-center text-primaryColor font-bold">
                Orders
              </h1>
            </div>
            <Filter />
          </div>

          <div>
            <div className="search-bar bg-stone-50 m-2 p-2  rounded-[10px]">
              <form
                className="flex items-center py-1 px-1  leading-normal bg-primaryLightColorInput text-gray-800  rounded-[10px] search-input"
                onSubmit={(e) => onSubmit(e)}
              >
                <input
                  className="w-full py-1 px-2  leading-normal bg-transparent border-none rounded-[10px] text-gray-800  focus:outline-none"
                  type="search"
                  placeholder="Search Customer No or Order ID"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setSearchData(null);
                  }}
                />
                <SearchIcon
                  width="4rem"
                  height="2rem"
                  className="text-primaryColor py-1 font-bold text-3xl bg-primaryLightColor25 cursor-pointer rounded-tr-[10px] rounded-br-[10px]"
                  onClick={onSubmit}
                />
              </form>

              <div className="order-status flex flex-wrap justify-start m-2 text-sm  ">
                <button
                  className=" m-1 text-black-400 bg-gray-200 focus:outline-none focus:ring focus:ring-grey-700 rounded-[10px] font-bold p-1   button-complete"
                  onClick={onAllOrderClick}
                >
                  {" "}
                  All Orders
                </button>
                <button
                  className=" m-1 text-green-500 bg-green-200 focus:outline-none focus:ring focus:ring-green-600 rounded-[10px] font-bold p-1    button-complete"
                  onClick={onCompletedClick}
                >
                  {" "}
                  Completed
                </button>
                <button
                  className=" m-1 text-red-500 bg-red-200 focus:outline-none focus:ring focus:ring-red-600 rounded-[10px] font-bold p-1    button-cancelled"
                  onClick={onCancelledClick}
                >
                  Cancelled
                </button>
                <button
                  className=" m-1 text-sky-500 bg-sky-200 focus:outline-none focus:ring focus:ring-sky-600 rounded-[10px] font-bold  p-1 button-closed"
                  onClick={onPreparingClick}
                >
                  Preparing
                </button>
              </div>
            </div>
          </div>

          <div
            id="scrollableDiv"
            className="right-sidebar-orders h-100 max-h-[600px] overflow-y-auto"
          >
            {" "}
            {!searchData ? outputFunction() : searchOutputFunction()}
          </div>
          <div className="flex justify-between items-center">
            <h6 className="font-bold text-mutedColor ml-1">
              {totalItemsLength} of {totalOrders}
            </h6>
            <div className="flex items-center">
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-l-[10px]"
                onClick={handlePrevClick}
                disabled={disablePrevButton}
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </button>
              <button className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor font-bold cursor-default">
                Page {currentPage}
              </button>
              <button
                className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-r-[10px]"
                onClick={handleNextClick}
                disabled={disableNextButton}
              >
                <ChevronRightIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
        </section>

        <section className="right-section w-full  h-[calc(100%_-_80px)]">
          {/* right*/}
          {singleOrder.data ? (
            <OrderDetail Order={singleOrder} />
          ) : (
            <div className="right-section w-full  h-[calc(100%_-_50px)] relative">
              <div className="flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] p-1 m-2">
                <div
                  className="p-2 bg-primaryLightColor25 rounded-[10px] text-primaryColor cursor-pointer"
                  onClick={ToggleMainSidebar}
                >
                  <MenuAlt2Icon className="w-6 h-6" />
                </div>

                <h1 className="font-bold text-2xl text-[#999999]">
                  <span className="text-[#17202A]"></span>
                </h1>

                <Notification />
              </div>

              <Sidebar
                isOpenMainSidebar={isOpenMainSidebar}
                setIsOpenMainSidebar={setIsOpenMainSidebar}
                width="100%"
                height="100%"
              />
              <Nodata height={100} />
            </div>
          )}
        </section>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allOrders: state.orderList.allOrderList,
    completedOrders: state.orderList.completedOrderList,
    cancelledOrders: state.orderList.cancelledOrderList,
    preparingOrders: state.orderList.preparingOrderList,
    singleOrder: state.orderList.singleOrder,
  };
};
export default connect(mapStateToProps, {
  fetchOrders,
  fetchAllOrders,
  fetchCancelledOrders,
  fetchPreparingOrders,
  fetchSingleOrder,
  showLoader,
  hideLoader,
})(OrderHistory);
