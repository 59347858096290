import {
    PRODUCT_LIST_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
} from "../constants/productsConstants";
import {get } from "lodash";
import store from "../store";
import orders from "../api/orders";

//ACTION TO GET THE PRODUCT LIST BASED ON TH SEARCH QUERY AND/OR CATEGORY OR GETTING A DEFAULT LIST OF ALL ITEMS OTHERWISE.
export const getProductsList =
    (searchQuery = "", currentPage = 1, reset = true, category = "", branch) =>
    async(dispatch) => {
        dispatch({ type: PRODUCT_LIST_REQUEST });

        try {
            //GETTING THE TOKEN AND ADDING IT TO CONFIG
            const token = get(store.getState(), "userLogin.userInfo.token");
            const config = {
                headers: { "Content-Type": "application/json", token: token },
            };
            //USING TERNARY OPERATORS TO MAKE REQUEST ACCORDINGLY
            const { data } = await orders.get(
                    `/get_product/${currentPage}?branch=${branch}&${searchQuery ? `search=${searchQuery}&` : ""
          }${category ? `category=${category}` : ""}`,
          config
        );

        //DISPATCHING THE RECEIVED LIST OF PRODUCTS
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: { data, reset } });
      } catch (error) {
        //DISPATCHING THE ERROR IF FOUND.
        dispatch({
          type: PRODUCT_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };