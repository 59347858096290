import React, {
  useState,
  Fragment,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  RefreshIcon,
  MinusIcon,
  PlusIcon,
  PlusCircleIcon,
} from "@heroicons/react/solid";
import { IoWalletOutline, IoCashSharp, IoCardSharp, IoWallet } from "react-icons/io5";
import { BsBank2 } from "react-icons/bs";

import { MdOutlineCancel } from "react-icons/md";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import store from "../../store";
import AddDiscountModal from "./AddDiscountModal";
import orders from "../../api/orders";
import OtpModal from "./OtpModal";
import AddMoney from "./AddMoney";
import DeliveryCharges from "./DeliveryCharges";
import WalletCard from "./WalletCard";
import DiscountedAddonModal from "./DiscountedAddonModal";
import DiscountedProductsModal from "./DiscountedProductsModal";
import { removeItemsFromCart } from "../../actions/cartAction";

const OnHoldModal = ({
  isCheckoutOpen,
  setIsCheckoutOpen,
  orderId,
  displayCustomerTypeDiscount,
  stop,
  duration,
  customerInfoRenderData,
  activeCustomerData,
  gettingActiveCustomerInfo,
  discountedAddonsProductModal,
  setDiscountedAddonsProductModal,
  AddonCouponList,
  discountedProductModal,
  setDiscountedProductModal,
  productsCouponList,
  discountPercAmount,
  discountOnly,
  hasApplyDiscount,
  discount,
  setDiscount,
  setIsDiscountOpen,
  isDiscountOpen,
  displayTotalAfterDiscount,
  setDisplayTotalAfterDiscount,
  displayTotalAmountAfterDiscper,
  setDisplayTotalAmountAfterDiscper,
  disablePlaceOrderButton,
  setDisablePlaceOrderButton,
  taxAmount,
  totalAmount2,
  subTotalAmount2
}) => {
  //HOOK DECLARATIONS AND INITIATIONS
  // console.log(customerInfoRenderData);

  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  //const [discount, setDiscount] = useState(null);
  const [couponData, setCouponData] = useState({});
  const [totalReceived, setTotalReceived] = useState(0);
  const [cashReceived, setCashReceived] = useState(0);
  const [floorNo, setFloorNo] = useState(1);
  const [tableNo, setTableNo] = useState("");
  const [extraComment, setExtraComment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [orderType, setOrderType] = useState("Dine In");
  // const [checkedInput, setCheckedInput] = useState(true);
  // const [orderTypeChecked, setOrderTypeChecked] = useState(true);
  const [isOpenOtpModal, setIsOpenOtpModal] = useState(false);
  const [isMoneyOpen, setIsMoneyOpen] = useState(false);

  const [OtpResData, setOtpResData] = useState({});
  const [counter, setCounter] = React.useState(30);

  let completeButtonRef = useRef(null);

  //DECLARING CONSTANTS FOR AMOUNT BUTTONS
  const [number1] = useState(1);
  const [number2] = useState(2);
  const [number5] = useState(5);
  const [number10] = useState(10);
  const [number20] = useState(20);
  const [number50] = useState(50);
  const [number100] = useState(100);
  const [number200] = useState(200);
  const [number500] = useState(500);
  const [number2000] = useState(2000);
  const [resetAmount] = useState(0);
  //DECLARING USESTATE TO VARY THE NOTE COUNT
  const [notes1Count, setNotes1Count] = useState(0);
  const [notes2Count, setNotes2Count] = useState(0);
  const [notes5Count, setNotes5Count] = useState(0);
  const [notes10Count, setNotes10Count] = useState(0);
  const [notes20Count, setNotes20Count] = useState(0);
  const [notes50Count, setNotes50Count] = useState(0);
  const [notes100Count, setNotes100Count] = useState(0);
  const [notes200Count, setNotes200Count] = useState(0);
  const [notes500Count, setNotes500Count] = useState(0);
  const [notes2000Count, setNotes2000Count] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(subTotalAmount2);
  const [totalAmount, setTotalAmount] = useState(totalAmount2);
  //const [taxAmount, setTaxAmount] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [isDCOpen, setIsDCOpen] = useState(false);
  const [isWCOpen, setIsWCOpen] = useState(false);
  //GETTING THE CUSTOMER INFO FROM THE LOCAL STORAGE
  const customerInfoRender = JSON.parse(sessionStorage.getItem("customerInfo"));
  const [bypassOTP, setBypassOTP] = useState(false);
  const [walletamt, setWalletAmt] = useState(0);

  //CALCULATING TOTAL AMOUNT AFTER THE DISCOUNT IS BEING APPLIED
  /* const [displayTotalAfterDiscount, setDisplayTotalAfterDiscount] =
    useState(totalAmount);
  const [displayTotalAmountAfterDiscper, setDisplayTotalAmountAfterDiscper] =
    useState(totalAmount); */

  const mobile_no = useSelector(
    (state) => state?.customerInfo?.customer?.mobile_no
  );
  const customer_name = useSelector(
    (state) => state?.customerInfo?.customer?.customer_name
  );
  //GETTING THE EMPLOYEE ID FROM THE REDUX STORE
  const employeeIdFromStore = useSelector(
    (state) => state.userDetails?.user?.employee_id
  );
  //GETTING BRANCH ID, BRANCH NAME, ADDRESS FROM THE REDUX STORE
  const branchIdfromStore = useSelector(
    (state) => state.userDetails?.user?.branch_id
  );

  const branchNameFromStore = useSelector(
    (state) => state.userDetails?.user?.branch
  );
  const addressfromstore = useSelector(
    (state) => state.userDetails?.user?.address
  );
  //GETTING THE CART ITEMS DATA FROM THE LOCAL STORAGE
  let cartItemsData = JSON.parse(sessionStorage.getItem("cartItems"));

  //FUNCTION TO CLOSE THE MODAL
  function closeCheckoutModal() {
    setIsCheckoutOpen(false);
  }
  //FUNCTIONS TO HANDLE THE ONCLICK ON THE AMOUNT BUTTONS
  const number1btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number1
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number1);
        setNotes1Count((prevCount) => prevCount + 1);
      }
    }
  };
  const number2btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number2
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number2);
        setNotes2Count((prevCount) => prevCount + 1);
      }
    }
  };
  const number5btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number5
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number5);
        setNotes5Count((prevCount) => prevCount + 1);
      }
    }
  };
  const number10btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number10
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number10);
        setNotes10Count((prevCount) => prevCount + 1);
      }
    }
  };

  const number20btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number20
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number20);
        setNotes20Count((prevCount) => prevCount + 1);
      }
    }
  };
  const number50btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number50
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number50);
        setNotes50Count((prevCount) => prevCount + 1);
      }
    }
  };

  const number100btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number100
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number100);
        setNotes100Count((prevCount) => prevCount + 1);
      }
    }
  };

  const number200btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number200
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number200);
        setNotes200Count((prevCount) => prevCount + 1);
      }
    }
  };

  const number500btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number500
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number500);
        setNotes500Count((prevCount) => prevCount + 1);
      }
    }
  };

  const number2000btn = () => {
    if (paymentMethod !== "card" && paymentMethod !== "wallet" && paymentMethod !== "wallet + card") {
      if (
        (paymentMethod === "card + cash" || paymentMethod === "wallet + cash") &&
        (hasApplyDiscount
          ? discountOnly.discount_value
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : Math.round(displayTotalAmountAfterDiscper * 100) / 100
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100) <
        cashReceived + number2000
      ) {
        alert.error("Cash Amount can't be more than total");
      } else {
        setCashReceived(cashReceived + number2000);
        setNotes2000Count((prevCount) => prevCount + 1);
      }
    }
  };
  //FUNCTION TO HANDLE RESET BUTTON ON THE MODAL
  const resetReceivedAmount = () => {
    setCashReceived(resetAmount);
    setNotes1Count(resetAmount);
    setNotes2Count(resetAmount);
    setNotes5Count(resetAmount);
    setNotes10Count(resetAmount);
    setNotes20Count(resetAmount);
    setNotes50Count(resetAmount);
    setNotes100Count(resetAmount);
    setNotes200Count(resetAmount);
    setNotes500Count(resetAmount);
    setNotes2000Count(resetAmount);
    setWalletAmt(0);
  };

  // console.log(customerInfoRender,"foronhold")
  //FUNCTION TO HANDLE THE COUPONS DISCOUNT
  const validCouponHandle = () => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };

    orders
      .post(
        "/all_valid_coupons",
        {
          branch_id: branchIdfromStore,
          customer_no:
            customerInfoRender[0]?.mobile_no || customerInfoRender?.mobile_no,
          customer_id:
            customerInfoRender[0]?.customer_id || customerInfoRender?.customer_id,
          employee_id: employeeIdFromStore,
          price: subTotalAmount,
          bday: sessionStorage.getItem("bday"),
        },
        config
      )
      .then((res) => {
        if (res.data === "Token does not match") {
          sessionStorage.clear();
          window.location.href = "/login";
        }
        setCouponData(res.data?.all_coupon || []);
        if (res.data.status === "Success") {
          return;
        }
        if (res.data.status === "failure") {
          return;
        }
      })
      .catch((error) => { });
  };

  // VERIFY OTP POST API CALL
  // console.log(couponData, "bro");

  const OtpSend = () => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };
    return new Promise(function (resolve, reject) {
      orders
        .post(
          "/send_otp_memb_cust",
          {
            mobile_no: customerInfoRender[0]?.mobile_no,
          },
          config
        )
        .then((res) => {
          if (res.data === "Token does not match") {
            sessionStorage.clear();
            window.location.href = "/login";
          }
          if (res.data.status === "success") {
            alert.success("OTP Sent !");
            resolve(true);
            return;
          }
          if (res.data.status === "failure") {
            resolve(false);
            return false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    })
  };

  //GETTING THE CART ITEMS FROM THE REDUX STORE
  const { cartItems } = useSelector((state) => state.cart);
  //CALCULATING THE SUBTOTAL AMOUNT

  useEffect(() => {
    let st = 0;
    cartItems.forEach(
      (item) => (st += Number(item.price + item.add_on_price) * item.qty)
    );
    setSubTotalAmount(st);
  }, [cartItems]);



  //DISABLING THE FLOOR COUNTER WHEN FLOOR NO IS ZERO
  const disableDecFloor = floorNo === 0;
  //FUNCTIONS TO HANDLE THE FLOOR COUNT
  const incFloorNo = () => {
    setFloorNo(floorNo + 1);
  };

  const decFloorNo = () => {
    setFloorNo(floorNo - 1);
  };

  let cartItemsModified = [];
  //const hasApplyDiscount = !!discount;
  //const discountOnly = discount;
  //MAPPING THE CART ITEMS INTO THE CART ITEMS MODIFIED ARRAY
  if (cartItemsData != null) {
    for (let i = 0; i < cartItemsData.length; i++) {
      const item = cartItemsData[i];
      let total_price = 0;
      total_price += Number(item.price + item.add_on_price) * item.qty;
      cartItemsModified.push({
        product_id: item.id,
        product_name: item.name,
        quantity: item.qty,
        price: item.price,
        total_price: total_price,
        add_ons: item.add_ons,
        add_ons_price: item.add_on_price,
        discount: item.discount !== undefined ? item.discount : 0,
        product_type: item.product_type,
        prepare_time: item.prepare_time,
        food_type: item.food_type,
        order_sku: item.sku,
        comment: item.note,
      });
    }
  }

  //FUCNTIONS AND VARIABLES FOR ADD COMMENT, ORDER TYPE AND PAYMENT METHOD FIELD
  const comment = `Floor: ${floorNo}, Table: ${tableNo}, ${extraComment}`;

  const orderTypeRadioChange = (e) => {
    setOrderType(e);
    setDeliveryCharges(0);
    if (e === "Delivery") {
      setPaymentMethod("account");
      resetReceivedAmount();
    }
  };

  useEffect(() => {
    if (orderType !== "Delivery" && paymentMethod === "account") setPaymentMethod("cash");
  }, [paymentMethod, orderType])

  const paymentMethodRadioChange = (e) => {
    if (e === "wallet" && (customerInfoRender[0].wallet_balance <
      (hasApplyDiscount
        ? discountOnly.discount_value
          ? Math.round(displayTotalAfterDiscount * 100) / 100
          : Math.round(displayTotalAmountAfterDiscper * 100) / 100
        : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) / 100))) {
      alert.error("Insufficient Balance in Wallet");
      setIsCheckoutOpen(false);
      setIsMoneyOpen(true);
    }
    else {
      resetReceivedAmount();
      setPaymentMethod(e);
      if (e === "card + cash" || e === "wallet + cash") {
        alert.info("Please Select Cash !");
      }
      if (e === "wallet + card") {
        alert.info("Please Enter Wallet Amount !");
        setIsWCOpen(true);
      }
    };
  };



  // const discountPercAmount = discountOnly?.max_discount_valid === true ? (discountOnly?.max_discount_value) : (((discountOnly?.discount_value / 100) * totalAmount).toFixed(0))

  useEffect(() => {
    if (hasApplyDiscount) {
      if (discountOnly.discount_type === "flat") {
        setDisplayTotalAfterDiscount(
          totalAmount -
          discountOnly.discount_value -
          displayCustomerTypeDiscount
        );
        setDiscountAmount(discountOnly.discount_value);
        setCouponCode(discountOnly.code);
      } else if (discountOnly.discount_type === "percentage") {
        setDisplayTotalAmountAfterDiscper(
          totalAmount - discountPercAmount - displayCustomerTypeDiscount
        );
        setDiscountAmount(displayTotalAmountAfterDiscper);
        setCouponCode(discountOnly.code);
      } else if (discountOnly.discount_type === "product") {
        setDisplayTotalAfterDiscount(
          totalAmount -
          discountOnly.discount_value -
          displayCustomerTypeDiscount
        );
        setDiscountAmount(discountOnly.discount_value);
        setCouponCode(discountOnly.code);
      } else if (discountOnly.discount_type === "addon") {
        setDisplayTotalAfterDiscount(
          totalAmount -
          discountOnly.discount_value -
          displayCustomerTypeDiscount
        );
        setDiscountAmount(discountOnly.discount_value);
        setCouponCode(discountOnly.code);
      }
    }
  }, [hasApplyDiscount, totalAmount, discountOnly, discountPercAmount, displayTotalAmountAfterDiscper, displayCustomerTypeDiscount, setDisplayTotalAfterDiscount, setDisplayTotalAmountAfterDiscper]);

  //CALCULATING THE CHANGE AMOUNT
  const changeAmount =
    Math.round(
      (cashReceived -
        deliveryCharges -
        (hasApplyDiscount
          ? discountOnly.discount_type === "flat"
            ? Math.round(displayTotalAfterDiscount * 100) / 100
            : discountOnly.discount_type === "percentage"
              ? Math.round(displayTotalAmountAfterDiscper * 100) / 100
              : discountOnly.discount_type === "product"
                ? Math.round(displayTotalAfterDiscount * 100) / 100
                : discountOnly.discount_type === "addon"
                  ? Math.round(displayTotalAfterDiscount * 100) / 100
                  : ""
          : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
          100)) *
      100
    ) / 100;

  //FUNCTION TO HANDLE THE CHECK OUT
  const OnCheckOut = () => {
    if ((paymentMethod === "card + cash" || paymentMethod === "wallet + cash") && (cashReceived === 0)) {
      alert.error("Please Select some cash !");
    }
    else {
      setDisablePlaceOrderButton(true);
      const token = get(store.getState(), "userLogin.userInfo.token");

      const config = {
        headers: { "Content-Type": "application/json", token: token },
      };

      let cash = cashReceived,
        card = totalReceived - cashReceived,
        sgst = Number((taxAmount / 2).toFixed(2)),
        cgst = Number((taxAmount / 2).toFixed(2)),
        change;
      card = Number(card.toFixed(2));
      if (paymentMethod === "cash") change = changeAmount;
      else change = 0;

      let paidprice = hasApplyDiscount
        ? discountOnly.discount_type === "flat"
          ? Math.round(displayTotalAfterDiscount * 100) / 100
          : discountOnly.discount_type === "percentage"
            ? Math.round(displayTotalAmountAfterDiscper * 100) / 100
            : discountOnly.discount_type === "product"
              ? Math.round(displayTotalAfterDiscount * 100) / 100
              : discountOnly.discount_type === "addon"
                ? Math.round(displayTotalAfterDiscount * 100) / 100
                : ""
        : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) / 100;


      let d = duration.split(":");
      d = Number(d[0]) * 3600 + Number(d[1]) * 60 + Number(d[2]);

      let extra_dis = hasApplyDiscount
        ? discount.discount_type === "flat"
          ? Math.round(discount.discount_value * 100) / 100
          : discount.discount_type === "percentage"
            ? Math.round(discountPercAmount * 100) / 100
            : discount.discount_type === "product"
              ? Math.round(discount.discount_value * 100) / 100
              : discount.discount_type === "addon"
                ? Math.round(discount.discount_value * 100) / 100
                : ""
        : 0;

      const body = {
        order_id: orderId,
        customer_no: customerInfoRenderData.mobile_no,
        customer_name: `${customerInfoRenderData.first_name} ${customerInfoRenderData.last_name}`,
        employee_id: employeeIdFromStore,
        branch_id: branchIdfromStore,
        branch_name: branchNameFromStore,
        paid_price: paidprice,
        sub_total: subTotalAmount,
        discount: extra_dis,
        applied_coupons: {
          coupon: couponCode,
        },
        comment: comment,
        status: "Preparing",
        paid: true,
        payment_method: paymentMethod,
        payment_id: null,
        account_id: null,
        tax: taxAmount,
        received: totalReceived,
        change: change,
        cash_amount: (paymentMethod === "cash" ? paidprice : ((paymentMethod === "card + cash" || paymentMethod === "wallet + cash") ? cash : 0)),
        card_amount: (paymentMethod === "card" ? paidprice : ((paymentMethod === "card + cash" || paymentMethod === "wallet + card") ? card : 0)),
        account_amount: (paymentMethod === "account" ? paidprice : 0),
        wallet_amount: (paymentMethod === "wallet" ? paidprice : (paymentMethod === "wallet + cash" ? card : (paymentMethod === "wallet + card" ? walletamt : 0))),
        sgst: sgst,
        cgst: cgst,
        order_type: orderType,
        order_items: cartItemsModified,
        address: addressfromstore,
        ord_rec_time: duration,
        ord_rec_time_sec: d,
        membership_discount: hasApplyDiscount ? discountOnly.memb_apply === true ? displayCustomerTypeDiscount : 0 : displayCustomerTypeDiscount,
        bypass_otp: bypassOTP,
        delivery_charges: deliveryCharges
      };

      // console.log(body);
      orders
        .post(
          "/update_hold_order",
          body,
          config
        )
        .then((res) => {
          if (res.data.status === "success") {
            alert.success(`Order Placed Successfully in ${duration}`);
            sessionStorage.removeItem("cartItems");
            sessionStorage.removeItem("customerInfo");
            sessionStorage.removeItem("bday");
            setTableNo("");
            setFloorNo(1);
            stop();
            navigate("/success", {
              state: { order: cartItemsModified, singleOrderData: res.data.data },
            });
            return;
          }
          if (res.data.status === "failure") {
            setDisablePlaceOrderButton(false);
            return;
          }
        })
        .catch((error) => {
          setDisablePlaceOrderButton(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    setCashReceived(walletamt);
  }, [walletamt])

  const [disableV, setDisableV] = useState(true);

  useEffect(() => {
    if ((paymentMethod === "wallet" || paymentMethod === "wallet + card" || paymentMethod === "wallet + cash") && OtpResData.status !== "success") {
      setDisableV(false);
    }
    else if (paymentMethod !== "wallet" && (displayCustomerTypeDiscount !== 0 || hasApplyDiscount) && OtpResData.status !== "success") {
      setDisableV(false);
    } else {
      setDisableV(true);
    }
  }, [
    paymentMethod, setDisableV, OtpResData, displayCustomerTypeDiscount, hasApplyDiscount
  ]);

  //const [disablePlaceOrderButton, setDisablePlaceOrderButton] = useState(false);

  /* useEffect(() => {
    if (paymentMethod === "cash" && totalReceived < (hasApplyDiscount ? discountOnly.discount_value ? Math.round(displayTotalAfterDiscount * 100) / 100 : Math.round(displayTotalAmountAfterDiscper * 100) / 100 : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) / 100)) {
      setDisablePlaceOrderButton(true);
    }
    else if (paymentMethod === "wallet" && OtpResData.status !== "success") {
      setDisablePlaceOrderButton(true);
    }
    else if (paymentMethod !== "wallet" && (displayCustomerTypeDiscount !== 0 || hasApplyDiscount) && OtpResData.status !== "success") {
      setDisablePlaceOrderButton(true);
    }
    else {
      setDisablePlaceOrderButton(false);
    }
  }, [totalReceived, paymentMethod, OtpResData, displayCustomerTypeDiscount, hasApplyDiscount])
 */

  useEffect(() => {
    if (
      paymentMethod === "cash" &&
      totalReceived <
      (hasApplyDiscount
        ? discountOnly.discount_value
          ? Math.round(displayTotalAfterDiscount * 100) / 100
          : Math.round(displayTotalAmountAfterDiscper * 100) / 100
        : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) / 100)
    ) {
      setDisablePlaceOrderButton(true);
    } else if (
      (paymentMethod === "wallet" ||
        paymentMethod === "wallet + cash" ||
        paymentMethod === "wallet + card") &&
      OtpResData.status !== "success"
    ) {
      setDisablePlaceOrderButton(true);
    } else if (
      (customerInfoRenderData.first_name !==
        "Guest") &&
      (paymentMethod !== "wallet" ||
        paymentMethod !== "wallet + cash" ||
        paymentMethod !== "wallet + card") &&
      (displayCustomerTypeDiscount !== 0 || hasApplyDiscount) &&
      OtpResData.status !== "success"
    ) {
      setDisablePlaceOrderButton(true);
    } else {
      setDisablePlaceOrderButton(false);
    }
  }, [
    totalReceived,
    paymentMethod,
    OtpResData,
    displayCustomerTypeDiscount,
    hasApplyDiscount,
  ]);

  // const [disableV, setDisableV] = useState(true);

  // useEffect(() => {
  //   // if (customerInfoRenderData?.customer_type !== "General") {
  //   if (
  //     displayCustomerTypeDiscount !== 0 ||
  //     !!discount?.discount_value ||
  //     !!discountPercAmount ||
  //     paymentMethod === "wallet"
  //   ) {
  //     setDisableV(false);
  //   } else {
  //     setDisableV(true);
  //   }
  //   // }
  // }, [
  //   displayCustomerTypeDiscount,
  //   hasApplyDiscount,
  //   setDisableV,
  //   customerInfoRenderData,
  //   discount,
  //   discountPercAmount,

  //   paymentMethod,
  // ]);

  // // console.log(customerInfoRenderData[0]?.customer_type, "general hai");

  // const disablePlaceOrderButton =
  //   ((totalReceived <
  //     (hasApplyDiscount
  //       ? discountOnly.discount_value
  //         ? Math.round(displayTotalAfterDiscount * 100) / 100
  //         : Math.round(displayTotalAmountAfterDiscper * 100) / 100
  //       : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) /
  //       100) ||
  //     OtpResData.status !== "success") &&
  //     (totalReceived < subTotalAmount ||
  //       customerInfoRender?.customer_type !== "General")) ||
  //   (!disableV && OtpResData.status !== "success") || (paymentMethod === "wallet" && bypassOTP === true);

  const resetTimer = useCallback(() => {
    setCounter(30);
  }, [setCounter]);

  useEffect(() => {
    if (paymentMethod === "cash") {
      setTotalReceived(cashReceived);
    } else {
      setTotalReceived(Number(cashReceived) + Math.abs(changeAmount));
    }
  }, [cashReceived, setTotalReceived, paymentMethod, changeAmount]);

  useEffect(() => {
    if (paymentMethod === "wallet" && bypassOTP === true) {
      alert.error("OTP can't be by-passed during wallet payment.");
      setOtpResData({ status: null });
      setBypassOTP(false);
    }
  }, [bypassOTP, paymentMethod])

  /* useEffect(() => {
    //CALCULATING THE TAX AMOUNT
    let s = Number(subTotalAmount - (Math.round(displayCustomerTypeDiscount * 100) / 100));
    s = s - (hasApplyDiscount ? (discount.discount_value ? (Math.round(discount.discount_value * 100) / 100) : (Math.round(discountPercAmount * 100) / 100)) : 0);
    let taxamt = Number((s * 0.05).toFixed(2));
    setTaxAmount(taxamt);

    //CALCULATING THE TOTAL AMOUNT
    let totalAmt = subTotalAmount + taxAmount;
    setTotalAmount(totalAmt);
  }, [subTotalAmount, taxAmount, hasApplyDiscount, displayCustomerTypeDiscount, discount, discountPercAmount]); */

  //console.log(activeCustomerData)

  useEffect(() => {
    setTotalAmount(subTotalAmount + taxAmount)

  }, [subTotalAmount, taxAmount])

  // removing  discounted product apply by coupon

  const deleteCartItems = (sku) => {
    dispatch(removeItemsFromCart(sku));
  };

  // matching whether product coupon is applied and product become discounted product
  const checkWhetherProductIsDiscounted = cartItems
    .filter((discountedProduct) => discountedProduct?.product?.product_add_from)
    .map((filteredSku) => filteredSku.sku);


  //console.log(customerInfoRenderData)

  return (
    <>
      <Transition appear show={isCheckoutOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 "
          style={{ zIndex: "67" }}
          onClose={() => setIsCheckoutOpen(true)}
          initialFocus={completeButtonRef}
        >
          <div
            ref={completeButtonRef}
            className="min-h-screen px-4 text-center"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-[800px] p-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="px-1 mb-2 flex justify-between items-center border-l-2 border-b-2 rounded-2xl border-solid border-primaryDarkColor">
                  <h3 className="text-2xl uppercase font-bold text-primaryDarkColor">Checkout Order</h3>
                  <div className="flex gap-2">
                    {customerInfoRenderData?.first_name === "Guest" ? (
                      ""
                    ) : (
                      <div className="flex flex-wrap gap-1 bg-primaryLightColor25 border-2 border-dashed border-primaryColor rounded-[20px]">
                        <div className="text-xs  rounded-[10px] font-bold  text-primaryColor relative pr-7 py-1 pl-2 flex">
                          <IoWalletOutline className="w-4 h-4" />
                          &nbsp;₹
                          {customerInfoRenderData !== undefined &&
                            customerInfoRenderData.wallet_balance}
                        </div>
                        <div
                          className="text-xs px-1 py-1 rounded-[10px] text-white bg-gray-800 cursor-pointer absolute right-[19.5px] top-[16.8px]"
                          onClick={() => {
                            setIsMoneyOpen(true);
                            closeCheckoutModal();
                          }}
                        >
                          <PlusIcon className="w-3.5 h-3.5 text-white" />
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Title>
                <div className="w-full border-2 rounded-2xl border-dashed  p-1">
                  <div className="flex flex-wrap justify-center">
                    <div className="md:w-1/2 w-full md:mb-0 mb-4 pr-2">
                      <div className="flex justify-between items-start border-2 border-dashed rounded-2xl p-1 mb-1">
                        <div>
                          <div className="flex flex-wrap justify-start items-center gap-2 mb-3">
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes1Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number1btn}
                            >
                              1
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes1Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes1Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes2Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number2btn}
                            >
                              2
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes2Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes2Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes5Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number5btn}
                            >
                              5
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes5Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes5Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes10Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number10btn}
                            >
                              10
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes10Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes10Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes20Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number20btn}
                            >
                              20
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes20Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes20Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes50Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number50btn}
                            >
                              50
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes50Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes50Count}
                              </span>
                            </button>
                          </div>
                          <div className="flex flex-wrap justify-start items-center gap-2 mb-2">
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes100Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number100btn}
                            >
                              100
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes100Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes100Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes200Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number200btn}
                            >
                              200
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes200Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes200Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes500Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number500btn}
                            >
                              500
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes500Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes500Count}
                              </span>
                            </button>
                            <button
                              className={` px-2 font-bold rounded-[10px] relative ${notes2000Count > 0 ? 'bg-primaryColor text-white' : 'bg-primaryLightColor25 text-primaryColor'}`}
                              onClick={number2000btn}
                            >
                              2000
                              <span className={`text-[9px] rounded-full p-0.1 px-1 absolute bottom-[-5px] right-[-5px] ${notes2000Count > 0 ? 'bg-primaryLightColor25 text-primaryColor' : 'bg-primaryColor text-white'}`}>
                                {notes2000Count}
                              </span>
                            </button>
                          </div>
                        </div>
                        <div>
                          <div className="flex justify-between items-center gap-3">
                            <h6 className="text-gray-500 font-bold text-md">Recieved </h6>
                            <button
                              className="text-gray-700 text-md"
                              onClick={resetReceivedAmount}
                            >
                              <RefreshIcon className="w-5 h-5" />
                            </button>
                          </div>
                          <h6 className="text-gray-700 font-bold text-2xl">
                            &#8377;{paymentMethod !== "wallet + card" ? cashReceived : 0}
                          </h6>
                        </div>
                      </div>

                      <div className="border-2 border-dashed rounded-xl">
                        <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            Subtotal
                          </h6>
                          <h6 className="font-bold text-sm text-gray-500">
                            &#8377;{subTotalAmount}
                          </h6>
                        </div>
                        <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            Membership Discount
                          </h6>
                          <h6 className="font-bold text-sm text-gray-500">
                            &#8377;
                            {hasApplyDiscount ? discount.memb_apply === true ?
                              Number(displayCustomerTypeDiscount).toFixed(2)
                              : 0 : Number(displayCustomerTypeDiscount).toFixed(2)}
                          </h6>
                        </div>
                        {/* <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            Add Discount
                          </h6>
                          <div className="flex items-center justify-between">
                            {discount && (
                              <div className="flex justify-center items-center bg-[#664d034d] px-2 gap-[5px] m-1  rounded-full">
                                <button className="text-[#664d03] font-bold text-xs ">
                                  {discount?.code}
                                </button>
                                <MdOutlineCancel
                                  color="#664d03"
                                  onClick={() => setDiscount(null)}
                                  className="cursor-pointer m-1/2"
                                  size={20}
                                />
                              </div>
                            )}
                            <button
                              className="text-gray-700"
                              onClick={() => {
                                if (!customerInfoRender) {
                                  alert.error("Please Add customer first");
                                } else {
                                  setIsDiscountOpen(!isDiscountOpen);
                                  setIsCheckoutOpen(false);
                                  validCouponHandle();
                                }
                              }}
                            >
                              <h6 className="font-bold text-sm text-red-700">
                                {hasApplyDiscount ? (
                                  `₹${discount.discount_type === 'flat'
                                    ? (Math.round(discount.discount_value * 100) /
                                      100)
                                    : discount.discount_type === 'percentage' ? (Math.round(discountPercAmount * 100) / 100) : ''
                                  }`
                                ) : (
                                  <PlusCircleIcon className="w-5 h-5" />
                                )}
                              </h6>
                            </button>
                          </div>
                        </div> */}
                        {hasApplyDiscount && (

                          <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                            <h6 className="font-bold text-sm text-gray-500">
                              Add Discount
                            </h6>
                            <div className="flex items-center justify-between">
                              {discount && (
                                <div className="flex justify-center items-center bg-[#664d034d] px-2 gap-[5px] m-1  rounded-full">

                                  <DiscountedAddonModal
                                    discountedAddonsProductModal={
                                      discountedAddonsProductModal
                                    }
                                    setDiscountedAddonsProductModal={
                                      setDiscountedAddonsProductModal
                                    }
                                    AddonCouponList={AddonCouponList}
                                  //setIsCheckoutOpen={setIsCheckoutOpen}
                                  //setIsDiscountOpen={setIsDiscountOpen}
                                  />

                                  <DiscountedProductsModal
                                    discountedProductModal={
                                      discountedProductModal
                                    }
                                    setDiscountedProductModal={
                                      setDiscountedProductModal
                                    }
                                    productsCouponList={productsCouponList}
                                  //setIsCheckoutOpen={setIsCheckoutOpen}
                                  //setIsDiscountOpen={setIsDiscountOpen}
                                  />
                                  <button className="text-[#664d03] font-bold text-xs ">
                                    {discount?.code}
                                  </button>
                                  <MdOutlineCancel
                                    color="#664d03"
                                    onClick={() => {
                                      // sessionStorage.removeItem("cartItem");
                                      // removing discounted product
                                      deleteCartItems(
                                        checkWhetherProductIsDiscounted[0]
                                      );

                                      setDiscount(null);
                                    }}
                                    className="cursor-pointer m-1/2"
                                    size={20}
                                  />
                                </div>
                              )}
                              <button
                                className="text-gray-700"
                                onClick={() => {
                                  if (!customerInfoRender) {
                                    alert.error("Please Add customer first");
                                  } else {
                                    setIsDiscountOpen(!isDiscountOpen);
                                    setIsCheckoutOpen(false);
                                    validCouponHandle();
                                  }
                                }}
                              >
                                <h6 className="font-bold text-sm text-red-700">
                                  {hasApplyDiscount ? (
                                    `${discount.discount_type === "flat"
                                      ? "₹" +
                                      Math.round(
                                        discount.discount_value * 100
                                      ) /
                                      100
                                      : discount.discount_type === "percentage"
                                        ? "₹" +
                                        Math.round(discountPercAmount * 100) /
                                        100
                                        : discount.discount_type === "product"
                                          ? "₹" +
                                          Math.round(discountPercAmount * 100) /
                                          100
                                          : discount.discount_type === "addon"
                                            ? "₹" +
                                            Math.round(discountPercAmount * 100) /
                                            100
                                            : ""
                                    }`
                                  ) : (
                                    <PlusCircleIcon className="w-5 h-5" />
                                  )}
                                </h6>
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            SGST
                          </h6>
                          <h6 className="font-bold text-sm text-gray-500">
                            &#8377;{(taxAmount / 2).toFixed(2)}
                          </h6>
                        </div>
                        <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            CGST
                          </h6>
                          <h6 className="font-bold text-sm text-gray-500">
                            &#8377;{(taxAmount / 2).toFixed(2)}
                          </h6>
                        </div>
                        <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-md text-gray-700">
                            Total
                          </h6>
                          <h6 className="font-bold text-md text-gray-700">
                            &#8377;
                            {hasApplyDiscount
                              ? `${discountOnly.discount_type === "flat"
                                ? Math.round(
                                  displayTotalAfterDiscount * 100
                                ) / 100
                                : discountOnly.discount_type ===
                                  "percentage"
                                  ? Math.round(
                                    displayTotalAmountAfterDiscper * 100
                                  ) / 100
                                  : discountOnly.discount_type === "product"
                                    ? Math.round(
                                      (totalAmount -
                                        displayCustomerTypeDiscount) *
                                      100
                                    ) / 100
                                    : discountOnly.discount_type === "addon"
                                      ? Math.round(
                                        (totalAmount -
                                          displayCustomerTypeDiscount) *
                                        100
                                      ) / 100
                                      : null
                              }`
                              : Math.round(
                                (totalAmount - displayCustomerTypeDiscount) *
                                100
                              ) / 100}
                          </h6>
                        </div>
                        <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            Online Partner Charges
                          </h6>
                          <h6 className="font-bold text-sm">
                            &#8377;
                            {deliveryCharges}
                          </h6>
                        </div>
                        {paymentMethod === "wallet + card" && <div className="flex justify-between items-center border-b-2 border-dashed p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            Wallet Amount
                          </h6>
                          <h6 className="font-bold text-sm text-red-500">
                            &#8377;
                            {walletamt}
                          </h6>
                        </div>}
                        <div className="flex justify-between items-center p-1">
                          <h6 className="font-bold text-sm text-gray-500">
                            {paymentMethod === "cash"
                              ? "Change"
                              : paymentMethod === "wallet"
                                ? "Wallet Amount"
                                : orderType === "Delivery"
                                  ? "Account"
                                  : paymentMethod === "wallet + cash"
                                    ? "Wallet Amount"
                                    : "Card Amount"}
                          </h6>
                          <h6 className="font-bold text-sm text-red-500">
                            &#8377;
                            {paymentMethod === "cash"
                              ? cashReceived >= 1
                                ? changeAmount
                                : 0
                              : Math.abs(changeAmount)}
                          </h6>
                        </div>
                      </div>

                    </div>
                    <div className="md:w-1/2 w-full p-1">
                      <div className="flex justify-between items-center mb-1 gap-2 border-2 border-dashed rounded-xl p-1">
                        {/* <h6 className="font-bold text-gray-600 text-lg">
                          Order Type
                        </h6> */}
                        <div className="w-1/3">
                          <input
                            className="hidden peer"
                            type="radio"
                            name="orderTypeRadio"
                            id="orderTypeRadio1"
                            checked={orderType === "Dine In"}
                            onChange={() => orderTypeRadioChange("Dine In")}
                          />
                          <label
                            className="flex justify-center font-semibold uppercase p-1 text-gray-500 bg-white ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-transparent peer-checked:bg-primaryColor peer-checked:text-white"
                            htmlFor="orderTypeRadio1"
                          >
                            Dine In
                          </label>
                        </div>
                        <div className="w-1/3">
                          <input
                            className="hidden peer"
                            type="radio"
                            name="orderTypeRadio"
                            id="orderTypeRadio3"
                            checked={orderType === "Delivery"}
                            onChange={() => orderTypeRadioChange("Delivery")}
                            onClick={() => {
                              setIsDCOpen(true);
                              setIsCheckoutOpen(false);
                            }}
                          />
                          <label
                            className="flex justify-center font-semibold uppercase p-1 text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-transparent peer-checked:bg-primaryColor peer-checked:text-white"

                            htmlFor="orderTypeRadio3"
                          >
                            Delivery
                          </label>
                        </div>
                        <div className="w-1/3">
                          <input
                            className="hidden peer"
                            type="radio"
                            name="orderTypeRadio"
                            id="orderTypeRadio2"
                            checked={orderType === "Take Away"}
                            onChange={() => orderTypeRadioChange("Take Away")}
                          />
                          <label
                            className="flex justify-center font-semibold uppercase p-1 text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-transparent peer-checked:bg-primaryColor peer-checked:text-white"

                            htmlFor="orderTypeRadio2"
                          >
                            Take Away
                          </label>
                        </div>
                      </div>

                      <div className={`flex ${orderType === "Delivery" ? "justify-center" : "justify-center"} items-center mb-1 gap-2 border-2 border-dashed rounded-xl p-1`}>
                        {/* <h6 className="font-bold text-gray-600 text-lg mr-[10px]">
                          Payment {orderType === "Delivery" && <br />} Method
                        </h6> */}
                        <div className={`flex ${orderType === "Delivery" && "ml-[122px]"} justify-evenly items-center space-x-3`}>
                          {orderType !== "Delivery" ?
                            <><div className="">
                              <input
                                className="hidden peer"
                                type="radio"
                                name="paymentMethodRadio"
                                id="paymentMethodRadio1"
                                checked={paymentMethod === "cash"}
                                onChange={() => paymentMethodRadioChange("cash")}
                              />
                              <label
                                className="flex justify-center text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-primaryColor peer-checked:bg-primaryColor peer-checked:text-white p-1"
                                htmlFor="paymentMethodRadio1"
                              >
                                <IoCashSharp className="w-6 h-6" />
                              </label>
                              <div className="text-xs font-semibold text-gray-700 mt-[3px]">Cash</div>
                            </div>
                              <div className="">
                                <input
                                  className="hidden peer"
                                  type="radio"
                                  name="paymentMethodRadio"
                                  id="paymentMethodRadio2"
                                  checked={paymentMethod === "card"}
                                  onChange={() => paymentMethodRadioChange("card")}
                                />
                                <label
                                  className="flex justify-center p-1 text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-primaryColor peer-checked:bg-primaryColor peer-checked:text-white"
                                  htmlFor="paymentMethodRadio2"
                                >
                                  <IoCardSharp className="w-6 h-6" />
                                </label>
                                <div className="text-xs font-semibold text-gray-700 mt-[3px]">Card</div>
                              </div>
                              {customerInfoRenderData?.first_name !== "Guest" && (
                                <div className="">
                                  <input
                                    className="hidden peer"
                                    type="radio"
                                    name="paymentMethodRadio"
                                    id="paymentMethodRadio3"
                                    checked={paymentMethod === "wallet"}
                                    onChange={() => paymentMethodRadioChange("wallet")}
                                  />
                                  <label
                                    className="flex justify-center p-1 text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-primaryColor peer-checked:bg-primaryColor peer-checked:text-white"
                                    htmlFor="paymentMethodRadio3"
                                  >
                                    <IoWallet className="w-6 h-6" />
                                  </label>
                                  <div className="text-xs font-semibold text-gray-700 mt-[3px]">Wallet</div>
                                </div>
                              )}
                              {customerInfoRenderData?.first_name !== "Guest" && (
                                <div className="">
                                  <input
                                    className="hidden peer"
                                    type="radio"
                                    name="paymentMethodRadio"
                                    id="paymentMethodRadio4"
                                    checked={paymentMethod === "wallet + cash"}
                                    onChange={() => paymentMethodRadioChange("wallet + cash")}
                                  />
                                  <label
                                    className="flex justify-center p-1 text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-primaryColor peer-checked:bg-primaryColor peer-checked:text-white"
                                    htmlFor="paymentMethodRadio4"
                                  >
                                    <IoWallet className="w-6 h-6" />+<IoCashSharp className="w-6 h-6" />
                                  </label>
                                  <div className="text-xs font-semibold text-gray-700 mt-[3px]">Wallet/Cash</div>
                                </div>
                              )}
                              {customerInfoRenderData?.first_name !== "Guest" && (
                                <div className="">
                                  <input
                                    className="hidden peer"
                                    type="radio"
                                    name="paymentMethodRadio"
                                    id="paymentMethodRadio5"
                                    checked={paymentMethod === "wallet + card"}
                                    onChange={() => paymentMethodRadioChange("wallet + card")}
                                  />
                                  <label
                                    className="flex justify-center p-1 text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-primaryColor peer-checked:bg-primaryColor peer-checked:text-white"
                                    htmlFor="paymentMethodRadio5"
                                  >
                                    <IoWallet className="w-6 h-6" />+<IoCardSharp className="w-6 h-6" />
                                  </label>
                                  <div className="text-xs font-semibold text-gray-700 mt-[3px]">Wallet/Card</div>
                                </div>
                              )}
                              <div className="">
                                <input
                                  className="hidden peer"
                                  type="radio"
                                  name="paymentMethodRadio"
                                  id="paymentMethodRadio6"
                                  checked={paymentMethod === "card + cash"}
                                  onChange={() => paymentMethodRadioChange("card + cash")}
                                />
                                <label
                                  className="flex justify-center p-1 text-gray-500 bg-white   ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-primaryColor peer-checked:bg-primaryColor peer-checked:text-white"
                                  htmlFor="paymentMethodRadio6"
                                >
                                  <IoCashSharp className="w-6 h-6" />+<IoCardSharp className="w-6 h-6" />
                                </label>
                                <div className="text-xs font-semibold text-gray-700 mt-[3px]">Cash/Card</div>
                              </div></> :
                            <div className="">
                              <input
                                className="hidden peer"
                                type="radio"
                                name="paymentMethodRadio"
                                id="paymentMethodRadio2"
                                checked={paymentMethod === "account"}
                                onChange={() => paymentMethodRadioChange("account")}
                              />
                              <label
                                className="flex justify-center p-1 pr-[-10px] text-gray-500 bg-white ring-2 ring-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-primaryColor peer-checked:bg-primaryColor peer-checked:text-white "
                                htmlFor="paymentMethodRadio2"
                              >
                                <BsBank2 className="w-10 h-6" />
                              </label>
                              <div className="text-xs font-semibold text-gray-700 mt-[3px]">Account</div>
                            </div>}
                        </div>
                      </div>
                      <div className="border-2 border-dashed px-1 rounded-xl">
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col justify-start items-start mb-2">
                            <h6 className="font-bold text-gray-600 text-md">
                              Floor No.
                            </h6>
                            <div className="flex justify-center items-center">
                              <button
                                className="button-primary-light p-2 bg-primaryLightColor25 text-sm text-primaryColor rounded-l-[10px]"
                                onClick={decFloorNo}
                                disabled={disableDecFloor}
                              >
                                <MinusIcon className="h-5 w-5" />
                              </button>
                              <button className="button-pimary-light p-2 bg-primaryLightColor25 text-sm text-primaryColor font-bold w-[30px] cursor-default">
                                {floorNo}
                              </button>
                              <button
                                className="button-pimary-light p-2 bg-primaryLightColor25 text-sm text-primaryColor rounded-r-[10px]"
                                onClick={incFloorNo}
                              >
                                <PlusIcon className="h-5 w-5" />
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-col justify-end items-end mb-2">
                            <h6 className="font-bold text-gray-600 text-md">
                              Table No.
                            </h6>
                            <div className="flex justify-center items-center">
                              <input
                                type="number" min="0"
                                className="button-pimary-light p-2  pr-0 bg-primaryLightColorInput text-sm text-primaryColor font-bold w-[100px] cursor-default rounded-[10px] focus:outline-none" placeholder="Table No."
                                onChange={(e) => setTableNo(e.target.value)}
                                value={tableNo}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="w-full">
                          <textarea
                            className="bg-primaryLightColorInput text-gray-700 rounded-[10px] w-full focus:outline-primaryColor placeholder:text-primaryColor/70 p-1"
                            placeholder="Add New Comment"
                            rows="5"
                            onChange={(e) => setExtraComment(e.target.value)}
                            value={extraComment}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center mt-2 gap-2 border-2 border-dashed py-1 rounded-2xl">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 md:w-1/6 w-1/3"
                    onClick={closeCheckoutModal}
                  >
                    Close
                  </button>
                  {/* <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-gray-800 border border-transparent rounded-[10px] hover:bg-gray-900 w-1/3 "
                    onClick={() => {
                      setIsOnHoldOpen(true);
                    }}
                  >
                    Hold
                  </button> */}
                  {customerInfoRenderData.first_name !==
                    "Guest" && (
                      ((paymentMethod === "wallet" || paymentMethod === "wallet + cash" || paymentMethod === "wallet + card") || ((paymentMethod !== "wallet" || paymentMethod !== "wallet + cash" || paymentMethod !== "wallet + card") && (displayCustomerTypeDiscount !== 0 || hasApplyDiscount))) &&
                      <button
                        style={
                          disableV
                            ? { background: " #cccccc" }
                            : { background: "#2d3748" }
                        }
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-gray-800 border border-transparent rounded-[10px] hover:bg-gray-900 w-2/5 "
                        onClick={() => {
                          setIsOpenOtpModal(true);
                          // OtpSend();
                          resetTimer();
                          closeCheckoutModal();
                        }}
                        disabled={disableV}
                      >
                        Verify Otp
                      </button>
                    )}

                  <button
                    style={
                      disablePlaceOrderButton
                        ? { background: "rgba(102, 77, 3, .3)" }
                        : { background: "#664d03" }
                    }
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white border border-transparent rounded-[10px] hover:bg-primaryDarkColor w-2/5"
                    onClick={() => {
                      if (
                        totalReceived <
                        (hasApplyDiscount
                          ? (discountOnly.discount_type === 'flat')
                            ? (Math.round(displayTotalAfterDiscount * 100) / 100)
                            : (discountOnly.discount_type === 'percentage')
                              ? (Math.round(displayTotalAmountAfterDiscper * 100) /
                                100)
                              : (discountOnly.discount_type === 'product')
                                ? (Math.round(displayTotalAfterDiscount * 100) / 100)
                                : (discountOnly.discount_type === 'addon')
                                  ? (Math.round(displayTotalAfterDiscount * 100) / 100)
                                  : ''
                          : Math.round(
                            (totalAmount - displayCustomerTypeDiscount) * 100) / 100)
                      ) {
                        alert.error(
                          "Received amount should be greater than Total Amount"
                        );
                      } else if (orderType === "") {
                        alert.error("Please select order type");
                      } else if (paymentMethod === "") {
                        alert.error("Please select payment method");
                      } else {
                        OnCheckOut();
                      }
                    }}
                    disabled={disablePlaceOrderButton}
                  >
                    Place Order
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      {/* <AddDiscountModal
        isDiscountOpen={isDiscountOpen}
        setIsDiscountOpen={setIsDiscountOpen}
        couponData={couponData}
        setDiscount={setDiscount}
        setIsCheckoutOpen={setIsCheckoutOpen}
        subTotal={subTotalAmount}
        customerInfoRender={activeCustomerData}
        employeeIdFromStore={employeeIdFromStore}
        subTotalAmount={subTotalAmount}
      /> */}
      <OtpModal
        isOpenOtpModal={isOpenOtpModal}
        setIsOpenOtpModal={setIsOpenOtpModal}
        OtpSend={OtpSend}
        setOtpResData={setOtpResData}
        counter={counter}
        resetTimer={resetTimer}
        setCounter={setCounter}
        setIsCheckoutOpen={setIsCheckoutOpen}
        customerInfoRenderData={customerInfoRenderData}
        customerInfoRender={customerInfoRender !== null && customerInfoRender[0]}
        setBypassOTP={setBypassOTP}
        paymentMethod={paymentMethod}
      />
      <AddMoney
        isMoneyOpen={isMoneyOpen}
        setIsMoneyOpen={setIsMoneyOpen}
        gettingActiveCustomerInfo={gettingActiveCustomerInfo}
        customerInfoRenderData={customerInfoRenderData}
        setIsCheckoutOpen={setIsCheckoutOpen}
      />
      <DeliveryCharges isDCOpen={isDCOpen} setIsDCOpen={setIsDCOpen} goBack={setIsCheckoutOpen} setDeliveryCharges={setDeliveryCharges} />

      <WalletCard isWCOpen={isWCOpen} setIsWCOpen={setIsWCOpen} goBack={setIsCheckoutOpen} setWalletAmt={setWalletAmt} price={hasApplyDiscount ? `${discountOnly.discount_value ? Math.round(displayTotalAfterDiscount * 100) / 100 : Math.round(displayTotalAmountAfterDiscper * 100) / 100}` : Math.round((totalAmount - displayCustomerTypeDiscount) * 100) / 100} />
    </>
  );
};

export default OnHoldModal;
