import {
  CUSTOMER_INFO_SUCCESS,
  CUSTOMER_INFO_REQUEST,
  CUSTOMER_INFO_FAIL,
} from "../constants/customerInfoConstants";
import store from "../store";
import { get } from "lodash";
import orders from "../api/orders";

//ACTION TO GET THE CUSTOMER INFO FROM THE API USING MOBILE NUMBER

export const getCustomerInfo =
  (mobileno = "") =>
  async (dispatch) => {
    dispatch({ type: CUSTOMER_INFO_REQUEST });

    try {
      //FETCHING THE TOKEN AND ADDING IT TO CONFIG
      const token = get(store.getState(), "userLogin.userInfo.token");
      const config = {
        headers: { "Content-Type": "application/json", token: token },
      };
      //MAKING THE REQUEST
      const { data } = await orders.get(
        `/customer_search?mobile_no=${mobileno}`,
        config
      );

      //REDIRECTING TO LOGI-IN PAGE IF THE TOKEN DOES NOT MATCH
      if (data.data === "Token does not match") {
        sessionStorage.clear();
        window.location.href = "/login";
      }
      //DISPATCHING THE FETCHED DATA
      dispatch({ type: CUSTOMER_INFO_SUCCESS, payload: data });
    } catch (error) {
      //CATCHING THE ERROR AND DISPATCHING IT
      dispatch({
        type: CUSTOMER_INFO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
