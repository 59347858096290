import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { useAlert } from "react-alert";

// import axios from "axios";

import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import store from "../../store";
import orders from "../../api/orders";

const CancelModal = ({ isopencancel, setIsOnCancelOpen, orderId }) => {
  const navigate = useNavigate();

  let cartItemsData = JSON.parse(sessionStorage.getItem("cartItems"));

  let cartItemsModified = [];

  if (cartItemsData != null) {
    for (let i = 0; i < cartItemsData.length; i++) {
      const item = cartItemsData[i];
      const total_price =
        (item.price +
          Object.values(item?.add_on_price || {}).reduce((a, b) => a + b, 0)) *
        item.qty;
      cartItemsModified.push({
        product_id: item.id,
        product_name: item.name,
        quantity: item.qty,
        price: total_price,
        add_ons: item.add_ons,
        add_on_price: item.add_on_price,
        discount: item.discount,
        product_type: "Kitchen",
        prepare_time: "2h3m23s",
        food_type: "Veg",
        order_sku: item.sku,
        comment: item.note,
      });
    }
  }

  const alert = useAlert();

  function closeCancelModal() {
    setIsOnCancelOpen(false);
  }

  const Oncancel = () => {
    const token = get(store.getState(), "userLogin.userInfo.token");

    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };

    orders
      .post(
        "/update_order",
        {
          order_id: orderId,
          paid: false,
          status: "Cancelled",
          completed_time: null,
        },
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Your order is cancelled");
          sessionStorage.removeItem("cartItems");
          sessionStorage.removeItem("customerInfo");
          navigate("/cancelled");
          return;
        }
        if (res.data.status === "failure") {
          alert.error(`${res.data.msg}`);
          return;
        }
      })
  };

  return (
    <>
      <Transition appear show={isopencancel} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 "
          style={{ zIndex: "67" }}
          onClose={closeCancelModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xs p-4 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="text-center text-xl font-bold px-5 mb-5">
                  Are You Sure you would like to cancel this Order?
                </Dialog.Title>

                <div className="flex mt-2 gap-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-red-500 border border-transparent rounded-[10px] hover:bg-primaryDarkColor w-1/2"
                    onClick={Oncancel}
                  >
                    Yes, I'm Sure
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/2 "
                    onClick={closeCancelModal}
                  >
                    No, I'm Not
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CancelModal;
