import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import store from "../../store";
import baseurl from "../../api/orders";
import { useAlert } from "react-alert";

const CashierAmountModal = ({ isOpenCashierModal, setIsOpenCashierModal }) => {

  const alert = useAlert();

  const [cashierAmount, setCashierAmount] = useState("");

  /*   function closeModal() {
      setIsOpenCashierModal(false);
    } */

  const employeeIdFromStore = useSelector(
    (state) => state.userDetails?.user?.employee_id
  );

  const branchIdFromStore = useSelector(
    (state) => state.userDetails?.user?.branch_id
  );

  const addCashierAmountHandler = () => {
    const token = get(store.getState(), "userLogin.userInfo.token");

    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };

    baseurl.post(
      "/add_cashier",
      {
        employee_id: employeeIdFromStore,
        branch_id: branchIdFromStore,
        initial_cash: cashierAmount
      },
      config
    )
      .then((res) => {
        if (res.data.status === "success") {
          setIsOpenCashierModal(false);
          sessionStorage.setItem('cashier_popup', 1);
          sessionStorage.setItem("employee_cashier_id", res.data.data.emp_cashier_id);
          alert.success("Cash on Hand Added Successfully.");
          return;
        }
        if (res.data.status === "failure") {
          setIsOpenCashierModal(false);
          alert.error(`${res.data.msg}`);
          return;
        }
      })
      .catch((error) => {

      });
  };

  return (
    <Transition appear show={isOpenCashierModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 "
        style={{ zIndex: "67" }}
        onClose={() => { }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full md:w-full" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block w-full max-w-xs p-2 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <Dialog.Title className="font-bold text-center text-xl uppercase">
                Cashier Amount
              </Dialog.Title>
              <form onSubmit={(e) => {
                e.preventDefault();
              }}>
                <div className="w-full">
                  <div
                    className="w-full max-w-md p-2 mx-auto bg-white rounded-2xl mb-2  mt-2"
                    style={{ overflowY: "auto" }}
                  >
                    <input type="number" className="w-full p-2 text-md font-medium text-gray-700 bg-primaryLightColorInput rounded-[10px] focus:outline-primaryColor placeholder:text-primaryColor/70" placeholder="Cash on hand" value={cashierAmount} onChange={(e) => setCashierAmount(e.target.value)} min="0" required />
                  </div>
                </div>

                <div className="flex justify-center mt-2 gap-2">
                  {/* <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/3 "
                    onClick={closeModal}
                  >
                    Close
                  </button> */}

                  <button
                    type="Submit"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryColor border border-transparent rounded-[10px] hover:bg-primaryDarkColor w-2/3"
                    onClick={addCashierAmountHandler}
                  >
                    Submit
                  </button>

                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default CashierAmountModal