import React from "react";
import nodata from "../../assets/images/empty_cart.svg";

const Nodata = ({ height }) => {
  return (
    <div
      className={`flex flex-col justify-center items-center p-3 m-2 bg-stone-50 h-[calc(100vh-${height}px)] rounded-[10px]`}
    >
      <img src={nodata} alt="no items" style={{ height: "100px" }} />
      <h6 className="font-bold mt-1">No Data Found</h6>
    </div>
  );
};

export default Nodata;
