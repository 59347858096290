import React, { useCallback, useEffect, useState } from "react";
import Filter from "../components/layout/Filter";
import { FiShoppingCart } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import {
    ArrowRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MenuAlt2Icon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/outline";
import { connect, useSelector } from "react-redux";
import orders from "../api/orders";

import {
    fetchOrders,
    fetchCancelledOrders,
    fetchPreparingOrders,
    fetchSingleOrder,
    fetchAllOrders,
    showLoader,
    hideLoader,
} from "../actions/orderListAction";

import { useNavigate } from "react-router-dom";
import Nodata from "../components/home/Nodata";
import store from "../store";
import { get } from "lodash";
import Sidebar from "../components/layout/Sidebar";
import Notification from "../components/layout/Notification";
import Loader from "../components/home/Loader";
import Moment from "react-moment";
import 'moment-timezone';

import LogoutReportList from "../components/home/LogoutReportList";
import LogoutReportsDetailsDisplay from "../components/home/LogoutReportsDetailsDisplay";

let allLogoutReports = [];


const getLogoutReports = async (employee_id, branch_id) => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
        headers: {
            token: token,
        },
    };
    try {
        const response = await orders.post(
            `/get_employee_login_details`, { employee_id, branch_id },
            config
        );

        if (response.data.data === "Token does not match") {
            sessionStorage.clear();
            window.location.href = "/login";
        }
        allLogoutReports = response.data.data;
        return response.data.data;
    } catch (error) {
    }
};

const LogoutReports = () => {
    const navigate = useNavigate();
    const [visible, setVisible] = React.useState(true);
    const [isOpenMainSidebar, setIsOpenMainSidebar] = useState(false);
    const [logoutReportsData, setLogoutReportsData] = useState([]);
    const [employeeId, setEmployeeId] = useState("");
    const [branchId, setBranchId] = useState("");
    const [logIn, setLogIn] = useState("");
    const [logOut, setLogOut] = useState("");

    const ToggleMainSidebar = () => {
        setIsOpenMainSidebar(!isOpenMainSidebar);
    };

    const employee_id = useSelector(
        (state) => state.userDetails?.user?.employee_id
    );
    const branch_id = useSelector((state) => state.userDetails?.user?.branch_id);
    // get userinfo from redux store
    const userLogin = useSelector((state) => state.userLogin);
    const userBranch2 = useSelector((state) => state.userBranch);
    const verifyBranch2 = useSelector((state) => state.verifyBranch);
    const { userInfo } = userLogin;
    const { userBranch } = userBranch2;
    const { verifyBranch } = verifyBranch2;


    useEffect(() => {
        if (branch_id !== undefined && employee_id !== undefined) {
            getLogoutReports(employee_id, branch_id).then((data) => {
                setLogoutReportsData(data);
                /* if (data !== undefined) {
                    setLogoutId(data[0].employee_logout_details_id);
                } */
            });
        }
    }, [branch_id, employee_id]);

    //console.log(logoutReportsData)
    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        } else if (!userBranch) {
            navigate("/select-branch");
        } else if (!verifyBranch) {
            navigate("/branch-verify");
        }
    }, [userInfo, navigate, userBranch, verifyBranch]);


    return (
        <>
            <div>
                <button
                    className=" font-bold  py-2 px-4  leading-normal sm:hidden bg-white/50 hover:bg-primaryLightColor25 text-primaryColor p-2 rounded-[10px] backdrop-blur-[3px] block md:hidden shadow fixed bottom-[60px] right-[20px]  z-50"
                    onClick={() => setVisible(!visible)}
                >
                    {visible ? (
                        <FiShoppingCart className="text-3xl" />
                    ) : (
                        <MdOutlineCancel className="text-4xl" />
                    )}
                </button>
            </div>

            <div className="bg-stone-200 h-screen flex flex-col md:flex-row">
                <Loader />
                {/* left */}
                <section
                    className={`left-section w-full md:w-[700px] h-full flex flex-col${visible ? " active" : ""
                        }`}
                >
                    <div className=" flex justify-start items-center bg-stone-50 borderRadius rounded-[10px] m-2 p-1 gap-3">
                        <div className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px] cursor-pointer">
                            <ChevronLeftIcon
                                className="h-6 w-6"
                                onClick={() => {
                                    navigate("/");
                                }}
                            />
                        </div>

                        <div className="">
                            <h1 className="text-2xl text-center text-primaryColor font-bold">
                                Logout Reports
                            </h1>
                        </div>
                    </div>

                    {logoutReportsData?.map((logoutReport) => {
                        return (
                            <LogoutReportList
                                key={logoutReport.employee_logout_details_id}
                                logoutReport={logoutReport}
                                setEmployeeId={setEmployeeId}
                                setBranchId={setBranchId}
                                setLogIn={setLogIn}
                                setLogOut={setLogOut}
                            />
                        );
                    }) || <Nodata height={100} />}






                </section>

                {logIn ? (
                    <LogoutReportsDetailsDisplay employee_id={employeeId} branch_id={branchId} log_in={logIn} log_out={logOut} />
                ) : (
                    <div className="right-section w-full  h-[calc(100%_-_50px)] relative">
                        <div className="flex justify-between items-center bg-stone-50 borderRadius rounded-[10px] p-1 m-2">
                            <div
                                className="p-2 bg-primaryLightColor25 rounded-[10px] text-primaryColor cursor-pointer"
                                onClick={ToggleMainSidebar}
                            >
                                <MenuAlt2Icon className="w-6 h-6" />
                            </div>

                            <h1 className="font-bold text-2xl text-[#999999]">
                                <span className="text-[#17202A]"></span>
                            </h1>

                            <Notification />
                        </div>

                        <Sidebar
                            isOpenMainSidebar={isOpenMainSidebar}
                            setIsOpenMainSidebar={setIsOpenMainSidebar}
                            width="100%"
                            height="100%"
                        />
                        <Nodata height={100} />
                    </div>
                )}
            </div>
        </>
    );
};


export default LogoutReports
