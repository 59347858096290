import {
  XIcon,
  SearchIcon,
  UserCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
// import axios from "axios";
import { get } from "lodash";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerInfo } from "../../actions/customerInfoAction";
import NewCustomerSidebar from "./NewCustomerSidebar";
import nodata from "../../assets/images/empty_cart.svg";
import store from "../../store";
import orders from "../../api/orders";
import nodata2 from "../../assets/images/undraw_people_search_re_5rre.svg";
// import useTimer from "../../hooks/useTimer";

const UserSiderbar = ({
  isOpenUserSidebar,
  setIsOpenUserSidebar,
  setCustomerInfoRenderData,
  start,
  stop,
  reset,
  customerInfoRender,
  customerInfoRenderData
}) => {
  const state = useSelector(state => state);
  const customerSearch = useRef(null);
  if (isOpenUserSidebar && customerSearch.current !== null) customerSearch.current.focus();
  // const { start, stop, duration, reset } = useTimer;
  const [isOpenNewCustomerSidebar, setIsOpenNewCustomerSidebar] =
    useState(false);

  const [searchCustomer, setSearchCustomer] = useState("");

  const [addNumber, setAddNumber] = useState(false);

  const [birthdayFilter, setBirthdayFilter] = useState([]);

  const [showBdyUser, setShowBdyUser] = useState(false);

  const [customerList, setCustomerList] = useState([]);

  const [dataSucces, setDataSuccess] = useState(false);

  const [prev, setPrev] = useState(null);


  const dispatch = useDispatch();

  const ToggleUserSidebar = () => {
    isOpenUserSidebar === true
      ? setIsOpenUserSidebar(false)
      : setIsOpenUserSidebar(true);
  };

  if (isOpenNewCustomerSidebar) stop();
  else {
    if (customerInfoRender !== null || Object.keys(customerInfoRenderData).length !== 0 || state.cart.cartItems.length > 0)
      start();
  }

  const ToggleNewCustomerSidebar = (number) => {
    if (number !== undefined) setAddNumber(true);
    isOpenNewCustomerSidebar === true
      ? setIsOpenNewCustomerSidebar(false)
      : setIsOpenNewCustomerSidebar(true);
  };

  const customerInfoFromStore = useSelector((state) => state.customerInfo);

  const { loading: isCustomerInfoLoading, customer: customerInfoData } =
    customerInfoFromStore;

  // console.log(customerInfoData?.status, "shsh");

  useEffect(() => {
    if (searchCustomer.length > 3) {
      dispatch(getCustomerInfo(searchCustomer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCustomer]);

  useEffect(() => {
    if (
      customerInfoData?.data instanceof Array &&
      customerInfoData?.status === "success"
    ) {
      setCustomerList(customerInfoData?.data);
      setDataSuccess(true);
    } else {
      setCustomerList([]);
      setDataSuccess(false);
    }
  }, [customerInfoData]);

  // console.log(!!customerList, "check");

  const branchIdfromStore = useSelector(
    (state) => state.userDetails?.user?.branch_id
  );

  const branchNameFromStore = useSelector(
    (state) => state.userDetails?.user?.branch
  );

  const guestUseron = () => {
    const token = get(store.getState(), "userLogin.userInfo.token");

    const config = {
      headers: {
        token: token,
      },
    };
    orders
      .post(
        "/guest_customer",
        {
          branch_id: branchIdfromStore,
          branch: branchNameFromStore,
        },
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToggleUserSidebar();
          sessionStorage.setItem("customerInfo", JSON.stringify(res.data.data));

          setCustomerInfoRenderData(res.data.data);

          start();

          return;
        }
        if (res.data.status === "failure") {
          return;
        }
      })
      .catch((error) => { });
  };
  const empid = get(store.getState(), "userDetails.user.employee_id");
  const getPrev = () => {
    const token = get(store.getState(), "userLogin.userInfo.token");

    const config = {
      headers: {
        token: token,
      },
    };
    orders
      .get(
        `/fetch_last_10_order_customer?employee_id=${empid}`,
        config
      )
      .then((res) => {
        if (res.data.status === "success") {
          setPrev(res.data.data)
          return;
        }
        if (res.data.status === "failure") {
          return;
        }
      })
      .catch((error) => { });
  };
  if (prev === null && empid) getPrev();

  const onUseCustomer = (customer) => {
    sessionStorage.setItem("customerInfo", JSON.stringify(customer));
    // console.log(pitems);
    // if(pitems === null){

    // }
    // console.log(`/fetch_cust_popular_items?branch_id=${branch_id}&customer_id=${customer.customer_id}`);


    setCustomerInfoRenderData(customer);
    setSearchCustomer("");
    ToggleUserSidebar();

    start();
  };

  useEffect(() => {
    // console.log(customerInfoRenderData);
    if (Object.keys(customerInfoRenderData).length !== 0) {
      let customer = customerInfoRenderData;
      let branch_id = state.userDetails?.user?.branch_id;
      if (branch_id && customer.customer_id) {
        orders.get(`/fetch_cust_popular_items?branch_id=${branch_id}&customer_id=${customer.customer_id}`, {
          headers: {
            token: state.userLogin.userInfo.token
          }
        })
          .then((response) => {
           // console.log(response);
            let pi = [];
            if (response.data.status === "success") pi = response.data.data;
            sessionStorage.setItem("popularItems", JSON.stringify(pi));
          })
          .catch(err => console.log(err));
      }
    }
  }, [customerInfoRenderData]);

  const onUseBdyBoy = useCallback(() => {
    const mobileNumber = get(birthdayFilter, "[0].mobile_no");
    const customerData =
      customerList.find(
        (customer) => get(customer, "mobile_no") === mobileNumber
      ) ||
      get(birthdayFilter, "[0]") ||
      null;

    sessionStorage.setItem("customerInfo", JSON.stringify(customerData));
    sessionStorage.setItem("bday", "true");
    setCustomerInfoRenderData(customerData);
    reset();
    start();
    ToggleUserSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerList, birthdayFilter]);

  async function onToggleBirthday(e) {
    setShowBdyUser(e.target.checked);
    sessionStorage.setItem("bday", "false");
    if (!e.target.checked) {
      return;
    } else {
      try {
        const token = get(store.getState(), "userLogin.userInfo.token");

        const config = {
          headers: {
            token: token,
          },
        };
        const result = await orders.get(`/get_birthday_numbers/1`, config);
        if (result.data["status"] === "success") {
          setBirthdayFilter(result.data.data);
        }
      } catch (error) { }
    }
  }

  const showBirthday = () => {
    if (birthdayFilter[0]?.name) {
      return birthdayFilter.map((item, i) => (
        <div
          onClick={onUseBdyBoy}
          key={i}
          className="flex items-center justify-between p-2 bg-white rounded-[10px] shadow-sm mb-1 border-2 border-primaryColor/25 cursor-pointer "
        >
          <div className="flex gap-1 cursor-pointer">
            <UserCircleIcon className="w-9 h-9 text-primaryColor/30" />
            <div>
              <h6 className="font-bold text-gray-700 text-md leading-none capitalize">
                {item.name}
              </h6>
              <h6 className="font-bold text-gray-600 text-sm lining-nums">
                {item.mobile_no}
              </h6>
            </div>
          </div>
          <button
            className="text-primaryColor hover:text-primaryDarkColor"
            onClick={onUseBdyBoy}
          >
            <PlusCircleIcon className="w-5 h-5" />
          </button>
        </div>
      ));
    } else {
      return (
        <div className="flex flex-col justify-center items-center p-3 m-2 bg-stone-50 h-[55vh] rounded-[10px]">
          <img src={nodata} alt="no items" style={{ height: "100px" }} />
          <h6 className="font-bold mt-1">No Customer Found</h6>
        </div>
      );
    }
  };

  // console.log(dataSucces, "check");

  const ShowCustomer = () => {
    if (customerList.length === 0 && searchCustomer === "") {
      if (prev?.length > 0) {
        return (
          prev.map((customer, i) => {
            return (
              <div
                onClick={() => {
                  onUseCustomer(customer);
                }}
                key={i}
                className="flex items-center justify-between p-2 bg-white rounded-[10px] shadow-sm mb-1 border-2 border-primaryColor/25 cursor-pointer"
              >
                <div
                  className="flex gap-1 cursor-pointer"
                  onClick={() => {
                    onUseCustomer(customer);
                  }}
                >
                  <UserCircleIcon className="w-9 h-9 text-primaryColor/30" />
                  <div>
                    <h6 className="font-bold text-gray-700 text-md leading-none capitalize">
                      {customer?.first_name}
                    </h6>
                    <h6 className="font-bold text-gray-600 text-sm lining-nums">
                      {customer?.mobile_no}
                    </h6>
                  </div>
                </div>
                <button
                  className="text-primaryColor hover:text-primaryDarkColor"
                  onClick={() => onUseCustomer(customer)}
                >
                  <PlusCircleIcon className="w-5 h-5" />
                </button>
              </div>)
          })
        )
      }
      else {
        return (
          <div className="flex flex-col justify-center items-center p-3 m-2 bg-stone-50 h-[55vh] rounded-[10px]">
            <img src={nodata2} alt="no items" style={{ height: "100px" }} />
            <h6 className="font-bold mt-1">Customer Search</h6>
          </div>
        );
      }
    }
    if (dataSucces) {
      return (
        <div>
          {customerList.map((customer, i) => {
            return (
              <div
                onClick={() => {
                  onUseCustomer(customer);
                }}
                key={i}
                className="flex items-center justify-between p-2 bg-white rounded-[10px] shadow-sm mb-1 border-2 border-primaryColor/25 cursor-pointer"
              >
                <div
                  className="flex gap-1 cursor-pointer"
                  onClick={() => {
                    onUseCustomer(customer);
                  }}
                >
                  <UserCircleIcon className="w-9 h-9 text-primaryColor/30" />
                  <div>
                    <h6 className="font-bold text-gray-700 text-md leading-none capitalize">
                      {customer?.first_name}
                    </h6>
                    <h6 className="font-bold text-gray-600 text-sm lining-nums">
                      {customer?.mobile_no}
                    </h6>
                  </div>
                </div>
                <button
                  className="text-primaryColor hover:text-primaryDarkColor"
                  onClick={() => onUseCustomer(customer)}
                >
                  <PlusCircleIcon className="w-5 h-5" />
                </button>
              </div>
            );
          })}
        </div>
      );
    } else if (!dataSucces && !customerList[0]?.first_name) {
      return (
        <div className="flex flex-col justify-center items-center p-3 m-2 bg-stone-50 h-[55vh] rounded-[10px]">
          <img src={nodata} alt="no items" style={{ height: "100px" }} />
          <h6 className="font-bold mt-1">No Match Found</h6>
          <button
            className="bg-primaryColor text-white w-fit mt-2 p-2 text-sm font-bold hover:bg-primaryDarkColor rounded-[10px] mb-2"
            onClick={() => ToggleNewCustomerSidebar(searchCustomer)}
          >
            Create New Customer
          </button>
        </div>
      );
    }
  };

  return (
    <>
      <div
        className={`sidebar-user p-2 ${isOpenUserSidebar === true ? "active" : ""
          }`}
      >
        <div className="sidebar-header p-1">
          <div className="flex justify-between items-center">
            <h6 className="text-gray-800 font-bold text-lg leading-tight uppercase">
              CUSTOMER
            </h6>
            <button
              className="button-pimary-light p-2 bg-primaryLightColor25 text-primaryColor rounded-[10px]"
              onClick={ToggleUserSidebar}
            >
              <XIcon className="h-6 w-6 close-icon" />
            </button>
          </div>
        </div>
        <div className="sidebar-body p-1">
          <button
            className="bg-primaryColor text-white w-full p-2 text-sm font-bold hover:bg-primaryDarkColor rounded-[10px] mb-2"
            onClick={() => ToggleNewCustomerSidebar()}
          >
            Create New Customer
          </button>
          <button
            className="bg-primaryLightColor25 text-primaryColor w-full p-2 text-sm font-bold hover:bg-primaryLightColor30 rounded-[10px] mb-3"
            onClick={guestUseron}
          >
            Use Guest
          </button>
          <div className="search-input-div relative mb-1">
            <input
              type="search"
              placeholder="Search Customer Mobile Number"
              onChange={(e) => {
                if (isNaN(e.target.value) || (e.target.value).length > 10) {
                  return false;
                }
                setSearchCustomer(e.target.value)
              }}
              value={searchCustomer}
              ref={customerSearch}
              className="w-full pl-[40px] rounded-[10px] p-2 bg-primaryLightColorInput text-textColor focus:outline-primaryColor"
            />
            <SearchIcon className="h-6 w-6 absolute top-[8px] left-[8px] text-primaryColor" />
          </div>
          <div className="flex items-center justify-start w-full mb-3">
            <label
              htmlFor="toggleB"
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggleB"
                  className="sr-only"
                  onChange={onToggleBirthday}
                  checked={showBdyUser}
                  value={birthdayFilter}
                />

                <div className="block bg-primaryLightColor25 w-7 h-4 rounded-full"></div>

                <div className="dot absolute left-0.5 top-0.5 bg-primaryColor/50 w-3 h-3 rounded-full transition"></div>
              </div>

              <div className="ml-2 text-gray-700 font-medium">
                Show Today's Birthday
              </div>
            </label>
          </div>
          <div className="w-full overflow-auto sidebar-users-div pr-1">
            {showBdyUser
              ? showBirthday()
              : !isCustomerInfoLoading && ShowCustomer()}
          </div>
        </div>
      </div>
      <div
        className={`sidebar-user-overlay ${isOpenUserSidebar === true ? "active" : ""
          }`}
      ></div>
      <NewCustomerSidebar
        isOpenNewCustomerSidebar={isOpenNewCustomerSidebar}
        setIsOpenNewCustomerSidebar={setIsOpenNewCustomerSidebar}
        setCustomerInfoRenderDta={setCustomerInfoRenderData}
        addNumber={addNumber ? searchCustomer : null}
        setAddNumber={setAddNumber}
      />
    </>
  );
};

export default UserSiderbar;
