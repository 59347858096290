import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { get } from "lodash";
import store from "../../store";
import orders from "../../api/orders";
import { useAlert } from "react-alert";

const OtpModal = ({
  isOpenOtpModal,
  setIsOpenOtpModal,
  OtpSend,
  counter,
  resetTimer,
  setCounter,
  setOtpResData,
  setIsCheckoutOpen,
  customerInfoRender,
  customerInfoRenderData,
  setBypassOTP,
  paymentMethod
}) => {
  const alert = useAlert();
  //  console.log(customerInfoRender)

  const [otp, setOtp] = useState("");
  const [checked, setChecked] = useState(false);
  const [otpSend, setOTPsend] = useState(false);

  let completeButtonRef = useRef(null);

  //FUNCTION TO CLOSE THE MODAL
  function closeModal() {
    setIsOpenOtpModal(false);
    setChecked(false);
    setIsCheckoutOpen(true);
  }

  // const mobileNO= customerInfoRender[0]?.mobile_no;

  // OTP VERIFICATION CALL
  const VerifyOtp = () => {
    if (checked) {
      setOtpResData({ status: 'success', msg: 'OTP Bypassed Successfully!' });
      setBypassOTP(true);
      closeModal();
      setOtp("");
      alert.success("OTP Bypassed Successfully!");
      setCounter(0);
      setIsCheckoutOpen(true);
    }
    else {
      const token = get(store.getState(), "userLogin.userInfo.token");
      const config = {
        headers: { "Content-Type": "application/json", token: token },
      };

      orders
        .post(
          "/verify_mem_otp",
          {
            mobile_no:
              customerInfoRender?.mobile_no ||
              customerInfoRenderData[0]?.mobile_no,
            MEMB_OTP: otp,
          },
          config
        )
        .then((res) => {
          setOtpResData(res.data);
          if (res.data === "Token does not match") {
            sessionStorage.clear();
            window.location.href = "/login";
          }

          if (res.data.status === "success") {
            closeModal();
            setOtp("");
            alert.success(res.data.msg);
            setCounter(0);
            setIsCheckoutOpen(true);
            setBypassOTP(false);
            return;
          }
          if (res.data.status === "failure") {
            alert.error(res.data.msg);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const [disableSubmit, setDisableSubmit] = useState(true);
  useEffect(() => {
    if (checked) {
      setDisableSubmit(false);
    }
    else {
      if (otp.length === 4) setDisableSubmit(false);
      else setDisableSubmit(true);
    }
  }, [otp, checked, setDisableSubmit]);

  const disableResendOtp = counter !== 0;

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <Transition appear show={isOpenOtpModal} as={Fragment}>
        <Dialog
          initialFocus={completeButtonRef}
          as="div"
          className="fixed inset-0 "
          style={{ zIndex: "70" }}
          onClose={closeModal}
        //   initialFocus={cancelButtonRef}
        >
          <div
            ref={completeButtonRef}
            className="min-h-screen px-4 text-center"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
            </Transition.Child>


            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-sm p-3  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title>
                  <div className="uppercase font-bold text-xl text-center text-primaryDarkColor border-l-2 border-b-2 rounded-2xl border-solid border-primaryDarkColor mb-2">
                    OTP Verification
                  </div>
                </Dialog.Title>


                <div className="border-2  border-dashed rounded-2xl p-1">
                  <div className="flex flex-col m-auto text-center border-2  border-dashed rounded-2xl p-1">
                    <span>Enter the OTP you received at</span>
                    <div className="flex justify-center items-center gap-3">
                      <span className="font-bold">
                        +91 {customerInfoRender?.mobile_no || customerInfoRenderData[0]?.mobile_no}
                      </span>
                      {
                        otpSend === false && <div>
                          <button
                            type="button"
                            className="inline-flex w-fit justify-center  text-sm font-bold text-[white]  border border-transparent rounded-[5px] py-1 px-2 bg-red-500 hover:bg-red-700"
                            onClick={() => {
                              OtpSend().then(res => {
                                if (res) {
                                  setOtp("");
                                  resetTimer();
                                  setOTPsend(true);
                                }
                              });
                            }}
                          >
                            SEND OTP
                          </button>
                        </div>
                      }
                    </div>

                  </div>

                  <div className="px-2 pt-2">
                    <div className="outline flex outline-primaryLightColor30 relative input-primary my-2 w-full">
                      <input
                        type="number"
                        name="otp"
                        placeholder="Enter Four Digit OTP"
                        className="block p-2 tracking-[10px] font-bold text-xl appearance-none focus:outline-none bg-transparent placeholder:tracking-normal text-center"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                        disabled={checked}

                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center px-2">
                    {
                      otpSend === true &&
                      <div>
                        <button
                          type="button"
                          style={disableResendOtp ? { background: "silver" } : { background: "red" }
                          }
                          className={`inline-flex w-fit justify-center  text-sm font-bold text-white border border-transparent rounded-[5px] py-1 px-2`}
                          onClick={() => {
                            OtpSend();
                            setOtp("");
                            resetTimer();
                          }}
                          disabled={disableResendOtp || checked}
                        >
                          Resend
                        </button>
                        <span className="text-sm text-[black] font-bold uppercase">
                          {" "}
                          Otp in
                        </span>
                        <span className="text-[red]">{" "}{counter}s</span>
                      </div>
                    }
                    {/* {(paymentMethod !== "wallet" || paymentMethod !== "wallet + card") || paymentMethod !== "wallet + cash" &&
                      <div className="flex items-center">
                        <label
                          htmlFor="toggleB"
                          className="flex items-center"
                        >
                          <div className="mr-1 text-gray-700 font-xs font-bold uppercase">
                            By-pass OTP
                          </div>
                          <div className="relative  cursor-pointer"
                            onClick={() => setChecked(checked => !checked)}>
                            <input
                              type="checkbox"
                              id="toggleB"
                              className="sr-only"
                              checked={checked}
                              readOnly
                            />

                            <div className="block bg-primaryLightColor25 w-7 h-4 rounded-full"></div>

                            <div className="dot absolute left-0.5 top-0.5 bg-primaryColor/50 w-3 h-3 rounded-full transition"></div>
                          </div>

                        </label>
                      </div>
                    } */}
                  </div>
                </div>

                <div className="flex justify-center mt-2 gap-2 border-2  border-dashed rounded-2xl p-1">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/3 "
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    style={
                      disableSubmit
                        ? { background: "rgba(102, 77, 3, .3)" }
                        : { background: "#664d03" }
                    }
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryLightColor border border-transparent rounded-[10px] hover:bg-slate-800 w-2/3 "
                    onClick={VerifyOtp}
                    disabled={disableSubmit}
                  >
                    Submit
                  </button>


                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition >
    </>
  );
};

export default OtpModal;
